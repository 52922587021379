import Papa from 'papaparse'
import { ApiQueryService } from './ApiQueryService'
import store from '@/store'
import { Chromosome, Genome, GeneIDInfo, QTLg } from '@/types/Types'
import PixiConfig from '@/graph/Config'
import ColorUtils from '@/utils/ColorUtils'

export class GenomeService {
  static async getGenome (): Promise<Genome> {
    return new Promise((resolve) => {
      ApiQueryService.getFile(PixiConfig.datasetFolderName + 'genome.csv').then((response) => {
        const result = Papa.parse(response, { header: true, skipEmptyLines: true })
        const chromosomes: Array<Chromosome> = result.data.map((row: any) => ({
          name: row.name,
          shortName: row.shortName,
          length: parseInt(row.length),
          centromeres: (row.centromereStart && row.centromereEnd ? [parseInt(row.centromereStart), parseInt(row.centromereEnd)] : null),
          qtls: []
        }))
        if (chromosomes.length === 0) {
          console.warn('Empty genome data (genome.csv)')
        } else {
          // Store chromosome lengths into 'genome'
          const genome: Genome = {
            chromosomes,
            uniqueTraits: [],
            qtls: []
          }

          // Retrieve max chrom length for the default QTL length filter slider
          let maxQTLLength = 0
          chromosomes.forEach((chromosome) => {
            if (chromosome.length > maxQTLLength) {
              maxQTLLength = chromosome.length
            }
          })
          store.commit('genomeStore/setMaxQTLLength', maxQTLLength)
          store.commit('genomeStore/setLengthFilter', maxQTLLength)

          // Retrieve QTLs from genome_qtls.json
          this.getGenomeQTLs().then((data) => {
            const qtlsMap = new Map(Object.entries(data))
            // console.log('qtlsMap:', qtlsMap)

            // Bang!: a list of unique traits
            genome.uniqueTraits = Array.from(qtlsMap.keys())

            // Bang!: one big array of QTLs
            genome.qtls = Array.from(qtlsMap.values()).flat() as Array<QTLg>

            // Get color scale for unique traits
            const colorScale = ColorUtils.getColorScale(genome.uniqueTraits.length, true)

            // Create a genome color scale map
            const genomeColorScale = new Map()
            // Assign color to each QTL and genome color scale
            genome.qtls.forEach((qtl) => {
              qtl.color = colorScale[genome.uniqueTraits.indexOf(qtl.trait)]
              genomeColorScale.set(qtl.trait, qtl.color)
            })

            // Set genome color scale in the store (needed for the graph's QTL tracks)
            store.commit('metaStore/setGenomeColorScale', genomeColorScale)

            // Assign QTLs to chromosomes
            genome.chromosomes.forEach((chromosome) => {
              chromosome.qtls = genome.qtls.filter((qtl) => qtl.ref_path === chromosome.name)
            })
            // console.log('genome:', genome)

            resolve(genome)
          })
        }
      }).catch((error) => {
        console.warn('Unable to load genome data (genome.csv): ', error)
      })
    })
  }

  private static async getGenomeQTLs (): Promise<Array<any>> {
    return new Promise((resolve) => {
      ApiQueryService.getFile(PixiConfig.datasetFolderName + 'genome_qtls.json')
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.warn('Unable to load genome QTL data (genome_qtls.csv): ', error)
        })
    })
  }

  // private static async getCentromeres (): Promise<

  static async loadHomGroupsFile (): Promise<Record<string, Record<string, Array<GeneIDInfo>>>> {
    return new Promise<Record<string, Record<string, Array<GeneIDInfo>>>>((resolve) => {
      const file = PixiConfig.datasetFolderName + PixiConfig.homGroupsFileName
      ApiQueryService.getFile(file).then((res) => {
        const parsedData = Papa.parse(res, { delimiter: '\t', header: true, skipEmptyLines: true })
        const homGroupGenes: Record<string, Record<string, Array<GeneIDInfo>>> = {}

        parsedData.data.forEach((row: any) => {
          const homGroupID = row.Orthogroup
          const genesMap: Record<string, Array<GeneIDInfo>> = {}
          Object.keys(row).forEach((key: string) => {
            if (key !== 'Orthogroup') {
              if (row[key] === '') return
              const genes = row[key].split(', ').map((geneStr: string) => {
                const [chrom, geneID] = geneStr.split(';')
                return { chrom, geneID }
              })
              genesMap[key] = genes
            }
          })
          homGroupGenes[homGroupID] = genesMap
        })

        // console.log(homGroupGenes)
        resolve(homGroupGenes)
      }).catch((error) => {
        console.log('Unable to load homology group file (homGroups.tsv): ', error)
      })
    })
  }
}

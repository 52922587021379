import store from '@/store'
import Papa from 'papaparse'
import * as PIXI from 'pixi.js'
import PixiConfig from '@/graph/Config'
import chroma from 'chroma-js'
import SortingUtils from '@/utils/SortingUtils'
import { Chunk, GraphTrack, vcfInfo, vcfTrack, ZoomLevel } from '@/types/Types'
import Graph from '@/graph/Graph'
import { ApiQueryService } from '@/services/ApiQueryService'

type Metadata = { PathName: string, [key: string]: any }[]

const DataProvider = {
  loadDatasetFile () {
    return new Promise<void>((resolve, reject) => {
      ApiQueryService.getFile(PixiConfig.datasetFolderName + PixiConfig.datasetFileName).then((res) => {
        const datasetListRawParsed = Papa.parse(res, { header: true, skipEmptyLines: true })
        const datasetList = datasetListRawParsed.data

        store.commit('chunkStore/setDatasetList', datasetList)

        resolve()
      }).catch((error) => {
        reject(error)
      })
    })
  },

  checkAndLoadIndexFile () {
    return new Promise<void>((resolve, reject) => {
      if (store.state.chunkStore.trackMap.size === 0) {
        const file = store.state.chunkStore.datasetFolder + PixiConfig.indexFileName
        ApiQueryService.getFile(file).then((index) => {
          if (!('zoom_levels' in index)) {
            reject(new Error('No zoom levels in index file'))
          }
          store.commit('chunkStore/setZoomLevels', index.zoom_levels)
          // sort zoom levels
          index.zoom_levels = SortingUtils.sortZoomLevels(index.zoom_levels)

          // if we include the following line, then also consistently on the path names in the chunk files
          // in addition, pggb conventions have a lot of special chars, so we skip it for now
          // pathNames.map((item: any) => DataProvider.removeInvalidCharsAndTrim(item))

          if (!('path_names' in index)) {
            reject(new Error('No path names in index file'))
          }
          const trackMap = new Map()
          index.path_names.forEach((trackName: string, idx: number) => {
            trackMap.set(trackName, idx)
          })
          store.commit('chunkStore/setTrackMap', trackMap)
          // store.commit('metaStore/setSelectedSortOption', 'id')

          // The following function does the following: If binwidth is not already set, or the requested binwidth does not exist: use the highest binwidth in the data structure
          const zoomlevel = this.getZoomLevelObj(store.state.chunkStore.binWidth ? store.state.chunkStore.binWidth : 0)
          if (!zoomlevel) {
            alert('No zoom levels found')
            reject(new Error('No zoom levels found'))
          }
          store.commit('chunkStore/setBinWidth', zoomlevel.level)
          store.commit('chunkStore/setCurrentMaxBin', zoomlevel.num_bins)

          if (store.state.chunkStore.binWidth >= PixiConfig.denseViewCutoff) {
            store.commit('metaStore/setDenseView', true)
          }

          if ('readset_names' in index) {
            store.commit('metaStore/setReadsInFiles', true)
            store.commit('metaStore/setReadsetNames', index.readset_names)
          }

          if ('vcf_groups' in index) {
            store.commit('chunkStore/setVCFGroupNames', index.vcf_groups)
          }
          if ('vcfs' in index) {
            const vcfTracks = new Map()
            index.vcfs.forEach((obj: vcfInfo, idx: number) => {
              if ('group' in obj && index.vcf_groups.includes(obj.group)) {
                obj.idx = idx
                vcfTracks.set(obj.label, obj)
                store.commit('chunkStore/addRawVCFTrack', obj.label)
              }
            })
            store.commit('chunkStore/setVCFTracks', vcfTracks)
          }

          resolve()
        }).catch((error) => {
          reject(error)
        })
      }
    })
  },

  // This is specific for loading while using JSON files, needs different logic for API calls
  initMetadataFromFile () {
    return new Promise<void>((resolve) => {
      const readNames = store.state.metaStore.readsetNames
      if (store.state.metaStore.readsInFiles) {
        // TODO for later:
        // readNames.map((item: string) => DataProvider.removeInvalidCharsAndTrim(item))

        store.commit('metaStore/setReadsetNames', readNames)
      }

      // // Calculate the maximum width of the text
      // // The function removeInvalidCharsAndTrim would trim this to 30 chars on import
      const style = PixiConfig.getGenomeNameTextStyle()

      let maxSize = 0

      // iterate over all possible tracks; then we do not have to reload this function if different tracks are
      // viewed in different pangenome locations
      for (const k of store.state.chunkStore.trackMap.keys()) {
        // Pixi.js should define the type of the new signature
        const textMetric: PIXI.TextMetrics = PIXI.TextMetrics.measureText(k, style)
        if (maxSize < textMetric.width) {
          maxSize = textMetric.width
        }
      }

      if (store.state.metaStore.readsInFiles) {
        for (let i = 0; i < readNames.length; i++) {
          const textMetric: PIXI.TextMetrics = PIXI.TextMetrics.measureText(store.state.metaStore.readsetNames[i], style)

          if (maxSize < textMetric.width) {
            maxSize = textMetric.width
          }
        }
      }

      store.commit('metaStore/setMetaTextWidth', Math.ceil(maxSize))

      // ---------------------
      // Load metadata file
      // ---------------------
      this.loadMetaData()
        .catch(() => {
          // If metadata file is not provided, we can still sort by name
          store.commit('metaStore/setMetaDataCategories', ['Name'])
          // If metadata file is not provided, we need to set metaContainer's width after metaTextWidth
          store.commit('metaStore/setMetaContainerWidth', Math.ceil(store.state.metaStore.metaTextWidth))
        })
        .finally(() => {
          const treeMetadataFileName = PixiConfig.frontendFolderName + 'trees/' + store.state.chunkStore.dataset + '-tree.metadata.tsv'
          this.readTreeMetadataFile(treeMetadataFileName)
            .then(() =>
              resolve()
            )
        })
    })
  },

  loadMetaData () {
    return new Promise<void>((resolve, reject) => {
      ApiQueryService.getFile(PixiConfig.datasetFolderName + PixiConfig.metadataFileName).then((res) => {
        const metadataFileRawParsed = Papa.parse(res, { header: true, skipEmptyLines: true, dynamicTyping: true })
        const metadataFile: Array<Record<string, string | number | null>> = metadataFileRawParsed.data as Array<Record<string, string | number | null>>
        console.log(metadataFile)
        const metadataObject: Record<string, Record<string, string | number | null>> = {}
        const metadataColumnNames = metadataFileRawParsed.meta.fields as Array<string>
        if (!metadataColumnNames) {
          reject(new Error('no meta info columns given'))
          return
        }
        // Remove first column
        metadataColumnNames.splice(0, 1)

        metadataFile.forEach((value: Record<string, string | number | null>) => {
          let { PathName: pathName, ...valueData } = value
          if (store.state.chunkStore.trackMap.has(pathName + '_' + store.state.chunkStore.dataset)) {
            // To store only one metadata file for all datasets, the folders have to be named
            // by the chromosome to match the path names in the graph:
            // path names: C01_chr01, C02_chr02, ...
            // in metadata file: C01, C02, ...
            pathName = pathName + '_' + store.state.chunkStore.dataset
          }
          if (pathName) {
            metadataObject[pathName] = {}
            Object.keys(valueData).forEach((key: string) => {
              if (pathName) {
                metadataObject[pathName][key] = valueData[key]
              }
            })
          }
        })
        // TODO: iterate over store.state.chunkStore.trackMap and store null metadata for the paths that are not in the metadata file

        // Push 'name' to be able to sort by name with meta sorting arrows and enable it
        metadataColumnNames.push('Name')
        store.commit('pantoStore/addEnabledMetaCategories', 'Name')
        // Store categories already in the store, needed for assigning colors
        store.commit('metaStore/setMetaDataCategories', metadataColumnNames)

        // Assign color scales to metadata categories
        let metaMapping: Record<string, any> = {}
        for (let i = 0; i < metadataColumnNames.length; i++) {
          // This loop does not include 'Name' (metadataColumnNamesCount was not increased upon pushing 'Name')
          metaMapping = this.assignMetadataColors(metadataObject, metaMapping, i)
        }
        store.commit('metaStore/setColorLookupTable', metaMapping)

        // Set metadata container width, determines the left margin of the tracks
        store.commit('metaStore/setMetaContainerWidth',
          Math.ceil(metadataColumnNames.length * (PixiConfig.cellWidth + PixiConfig.cellMargin) + store.state.metaStore.metaTextWidth)
        )

        // Set meta data
        store.commit('metaStore/setMetaData', metadataObject)

        console.log('metaData', store.state.metaStore.metaData)

        resolve()
      }).catch((error) => {
        // console.warn(error)
        reject(error)
      })
    })
  },

  readTreeMetadataFile (filePath: string) {
    return new Promise<void>((resolve) => {
      ApiQueryService.getFile(filePath)
        .then((res) => {
          const rawParsed = Papa.parse(res, { header: true, skipEmptyLines: true, dynamicTyping: true })
          let treeMetadataColumnName = (rawParsed.meta.fields as Array<string>)[1]
          // The column name has the format <regionName>_tree. Change it to "Cluster <regionName>"
          treeMetadataColumnName = 'Cluster ' + treeMetadataColumnName.replace('_tree', '')
          const treeMetaDataRecord = (rawParsed.data as Metadata).reduce((acc, curr) => {
            const key = curr.PathName
            // key = key.replace('_' + store.state.chunkStore.dataset, '')
            const value = curr[Object.keys(curr).find(k => k !== 'PathName')!]
            acc[key] = value
            return acc
          }, {} as Record<string, number>)

          if (!treeMetaDataRecord) {
            return
          }

          // Store new metadata in the global meta data object
          for (const pathName in treeMetaDataRecord) {
            let valueRecord: Record<string, string | number | null> = {}
            if (pathName in store.state.metaStore.metaData) {
              valueRecord = store.state.metaStore.metaData[pathName]
            }
            if (pathName in treeMetaDataRecord) {
              valueRecord[treeMetadataColumnName] = treeMetaDataRecord[pathName]
            } else {
              valueRecord[treeMetadataColumnName] = null
            }
            if (!store.state.metaStore.metaDataCategories.includes(treeMetadataColumnName)) {
              // New category is added at seond-last index, last index is always 'Name'
              store.commit('metaStore/addMetaDataCategory', treeMetadataColumnName)
            }
            store.commit('metaStore/addMetaData', { key: pathName, value: valueRecord })
            store.commit('metaStore/setTreeMetadataColumnName', treeMetadataColumnName)
          }
          // TODO: iterate over store.state.chunkStore.trackMap and set treeMetaData to null for those paths not in the treeFile?

          // Assign colors to the new category (which is at the second-last index)
          store.commit('metaStore/setColorLookupTable', this.assignMetadataColors(
            store.state.metaStore.metaData,
            store.state.metaStore.colorLookupTable,
            store.state.metaStore.metaDataCategories.length - 2
          ))

          // Add treeMetadataColumnName to metadata categories (would trigger a redraw,
          // but we de-activate redraw here and want to do it only after resorting below)
          store.commit('metaStore/setBlockRedraw', true) // deactivate re-draw
          store.commit('pantoStore/addEnabledMetaCategories', treeMetadataColumnName)
          setTimeout(() => {
            store.commit('metaStore/setBlockRedraw', false) // activate redrawing again
            store.commit('metaStore/setSelectedSortOption', treeMetadataColumnName)
          }, 500)

          console.log('-> metaData', store.state.metaStore.metaData, store.state.metaStore.metaDataCategories, store.getters['metaStore/metaDataCategories'])
        })
        .catch((error) => {
          console.warn(error)
        })
        .finally(() => {
          resolve()
        })
    })
  },

  assignMetadataColors (metaData: Record<string, any>, metaMapping: Record<string, any>, i: number): Record<string, any> {
    const metadataColumnNames = store.state.metaStore.metaDataCategories
    const uniqSet: Set<any> = new Set()

    for (const key in metaData) {
      if (key !== 'undefined') {
        const value = metaData[key][metadataColumnNames[i]]
        uniqSet.add(value)
      }
    }

    const uniqMetadata = [...uniqSet]
    const categorical = uniqMetadata.filter((x: any) => x !== null && x !== undefined).every(isNaN)

    metaMapping[metadataColumnNames[i]] = {}

    const whiteColor = '#FFFFFF'

    // Use brewer color palette
    const colorPalette = chroma.brewer.Dark2

    let colorPaletteScale: chroma.Scale | null = null
    let colorPaletteArray: string[] = []

    if (categorical) {
      // For categorical (qualitative) data, use colors directly from the palette
      colorPaletteArray = Array(uniqMetadata.length).fill(null).map((_, index) => colorPalette[index % colorPalette.length])
    } else {
      // For non-categorical data, create a diverging scale with white in the middle
      const currentColorStart = chroma(colorPalette[i % colorPalette.length])
      const currentColorEnd = currentColorStart.set('hsl.h', '+180') // Complementary color
      colorPaletteScale = chroma.scale([currentColorStart, whiteColor, currentColorEnd])
    }

    uniqMetadata.forEach((value, index) => {
      if (value === '' || value === undefined || value === 'NA' || value === null) {
        metaMapping[metadataColumnNames[i]][value] = PixiConfig.metaMissingColor
      } else if (categorical) {
        metaMapping[metadataColumnNames[i]][value] = colorPaletteArray[index].replace('#', '0x')
      } else if (colorPaletteScale) {
        const numValue = Number(value)
        const validValues = uniqMetadata.filter((x: any) => x !== null && x !== undefined && !isNaN(x))
        const minValue = Math.min(...validValues.map(Number))
        const maxValue = Math.max(...validValues.map(Number))
        const normalizedValue = (numValue - minValue) / (maxValue - minValue)
        metaMapping[metadataColumnNames[i]][value] = colorPaletteScale(normalizedValue).hex().replace('#', '0x')
      }
    })

    return metaMapping
  },

  loadTracks (chunks: Array<number>, extend = false) {
    return new Promise<void>((resolve, reject) => {
      const path = store.state.chunkStore.datasetFolder + 'bin' + store.state.chunkStore.binWidth + '/'
      const promises = []

      // TODO: it might be easier to delete pathsToDraw and fill this up from scratch again, rather than delete chunks not needed anymore one by one !
      //       since it's likely that the new chunks are disjunct from the cached set of chunks
      // if (!extend) {
      //   // delete the cached chunks that are not in chunks, but don't touch cachedChunks when additional chunks should be loaded
      //   let cachedChunk: any // the next for-loop doesn't accept <number> as iterator...
      //   for (cachedChunk in store.state.chunkStore.cachedChunks) {
      //     if (!chunks.includes(+cachedChunk)) {
      //       // reduce path counts by the chunkObj counts that is not in the 'chunks' array:
      //       const chunkObj = store.state.chunkStore.cachedChunks[cachedChunk]
      //       for (const p of Object.keys(store.state.chunkStore.cachedChunks[cachedChunk].tracks)) {
      //         store.commit('chunkStore/deleteGraphTrack', { id: p, val: 'cov_bins' in chunkObj.tracks[p] ? chunkObj.tracks[p].cov_bins : chunkObj.nrBins })
      //         store.commit('chunkStore/deleteRawGraphTrack', { id: p, val: 'cov_bins' in chunkObj.tracks[p] ? chunkObj.tracks[p].cov_bins : chunkObj.nrBins })
      //       }
      //       store.commit('chunkStore/decrCachedBins', chunkObj.nrBins)
      //       store.commit('chunkStore/deleteCachedChunks', cachedChunk)
      //     }
      //   }
      // }

      for (const chunk of chunks) {
        promises.push(ApiQueryService.getFile(path + DataProvider.getFileName(chunk)).then((file) => {
          if (file) {
            this.preprocessChunk(file, chunk)
          }
        }).catch((error) => {
          reject(error)
        }))

        // load read track data
        if (store.state.metaStore.readsInFiles) {
          promises.push(ApiQueryService.getFile(path + DataProvider.getReadsFileName(chunk)).then((file) => {
            if (file.read_paths) {
              store.commit('chunkStore/addReadTracks', { id: chunk, data: file.read_paths })
            }
          }).catch((error) => {
            reject(error)
          }))
        }
      }

      Promise.allSettled(promises).then(() => {
        Graph.updateCachedChunksCoords()

        // restore the previous sort order
        SortingUtils.sortTracks('graph')

        // load VCF Tracks
        const promisesVCF = []
        // const vcfTracks = store.getters['chunkStore/getVisibleTracks'].vcfTracks
        // for (const trackName of vcfTracks) {
        //   promisesVCF.push(this.loadVCFTrack(trackName, chunks))
        // }
        for (const chunk of chunks) {
          for (const groupName of store.state.chunkStore.vcfGroupNames) {
            promisesVCF.push(ApiQueryService.getFile(path + DataProvider.getVCFFileName(groupName, chunk)).then((result) => {
              if (result && result.vcf_tracks.length) {
                result.vcf_tracks.forEach((obj: vcfTrack) => {
                  const payload = {
                    chunkID: chunk,
                    trackName: obj.t,
                    data: obj
                  }
                  store.commit('chunkStore/storeVCFTrack', payload)
                })
              }
            }).catch(() => {
              // chunks can be missing if they don't contain any variants
            }))
          }
        }

        Promise.allSettled(promisesVCF).then(() => {
          SortingUtils.sortTracks('vcf')
          resolve()
        })
      }).catch((error) => {
        reject(error)
      })
    })
  },

  loadVCFTrack (trackName: string, chunks: number[]) {
    return new Promise<void>((resolve, reject) => {
      const promises = []
      for (const chunk of chunks) {
        const chunkData = store.state.chunkStore.cachedChunks[chunk]
        if (!(trackName in chunkData.vcfTracks)) {
          const path = store.state.chunkStore.datasetFolder + 'bin' + store.state.chunkStore.binWidth + '/'
          promises.push(ApiQueryService.getFile(path + DataProvider.getVCFFileName(trackName, chunk)).then((result) => {
            if (result && result.vcf_tracks.length) {
              result.vcf_tracks.forEach((obj: vcfTrack) => {
                const payload = {
                  chunkID: chunk,
                  trackName: obj.t,
                  data: obj
                }
                store.commit('chunkStore/storeVCFTrack', payload)
              })
              // this.loadVCFTrack(result.data, chunk)
            }
          }).catch(() => {
            // chunks can be missing if they don't contain any variants
            // store an empty vcfTrack to indicate that this track does not need to be re-loaded
            const payload = {
              chunkID: chunk,
              trackName,
              data: { track_name: trackName, bins: [] }
            }
            store.commit('chunkStore/storeVCFTrack', payload)
          }))
        }
      }

      Promise.allSettled(promises).then(() => {
        // SortingUtils.sortTracks('vcf')
        resolve()
      }).catch((error) => {
        reject(error)
      })
    })
  },

  preprocessChunk (data: any, chunkID: number) {
    return new Promise<void>((resolve) => {
      const values: Chunk = { id: chunkID }

      let seq = data.sequence
      if (seq) seq = seq.replace(/(\r\n|\n|\r)/gm, '')

      const nrBins = data.last_bin - data.first_bin + 1

      const tracks: Record<string, GraphTrack> = {}
      data.graph_paths.forEach((obj: GraphTrack) => {
        const trackName = obj.path_name
        if (store.state.chunkStore.trackMap.has(trackName)) {
          tracks[trackName] = obj

          store.commit('metaStore/addSortingTableEntryGraph', trackName) // NEEDED?
          store.commit('chunkStore/addGraphTrack', { id: trackName, val: 'cov_bins' in obj ? obj.cov_bins : nrBins })
          store.commit('chunkStore/addRawGraphTrack', { id: trackName, val: 'cov_bins' in obj ? obj.cov_bins : nrBins })
        }
      })

      values.data = {
        firstBin: data.first_bin,
        firstCol: data.first_bin + data.xoffsets[0],
        lastBin: data.last_bin,
        lastCol: data.max_x,
        nrBins,
        nrCols: nrBins + data.xoffsets[nrBins - 1] - data.xoffsets[0],
        tracks,
        vcfTracks: {},
        xoffsets: data.xoffsets,
        sequence: seq
      }

      store.commit('chunkStore/addCachedBins', nrBins)
      store.commit('chunkStore/addCachedChunks', values)
      resolve()
    })
  },

  doesZoomLevelExist (binWidth: number, zoomLevels: Array<ZoomLevel> | null = null) {
    if (!zoomLevels) {
      zoomLevels = this.getAvailableBinWidths()
    }
    for (const zoomLevel of zoomLevels) {
      if (zoomLevel.level === binWidth) {
        return true
      }
    }

    return false
  },

  getAvailableBinWidths (): Array<ZoomLevel> {
    if (!store.state.chunkStore.zoomLevels == null) {
      return []
    }

    // Assuming we have numbers here...
    return store.state.chunkStore.zoomLevels.map((item: ZoomLevel) => (item.level))
  },

  getZoomLevelObj (binWidth: number) {
    for (const zoomLevel of store.state.chunkStore.zoomLevels) {
      if (zoomLevel.level === binWidth) {
        return zoomLevel
      }
    }

    // If not found return "highest" zoom level
    if (store.state.chunkStore.zoomLevels.length > 0) {
      // Zoom levels are sorted, so that task is easy:
      return store.state.chunkStore.zoomLevels[store.state.chunkStore.zoomLevels.length - 1]
    } else {
      return undefined
    }
  },

  getZoomLevelOrHigher (binWidth: number) {
    if (DataProvider.doesZoomLevelExist(binWidth)) {
      return binWidth
    } else {
      let b = DataProvider.getHigherZoomLevel(binWidth)
      if (b === null) {
        b = DataProvider.getAvailableBinWidths()[0].level
        if (b.length === 0) {
          return null
        } else {
          alert('No zoom level of ' + binWidth + ' or above available. Will take the first available: ' + b)
        }
      }
      return b
    }
  },

  getLowerZoomLevel (binWidth: number) {
    const zoomLevels = store.state.chunkStore.zoomLevels
    for (let i = 0; i < zoomLevels.length; ++i) {
      if (zoomLevels[i].level === binWidth && i !== 0) {
        return zoomLevels[i - 1].level
      }
    }

    return null
  },

  getHigherZoomLevel (binWidth: number) {
    const zoomLevels = store.state.chunkStore.zoomLevels
    for (let i = 0; i < zoomLevels.length; ++i) {
      if (zoomLevels[i].level === binWidth && i !== zoomLevels.length - 1) {
        return zoomLevels[i + 1].level
      }
    }

    return null
  },

  getAvailableDatasets () {
    if (!Array.isArray(store.state.chunkStore.datasetList) || !store.state.chunkStore.datasetList.length) {
      this.loadDatasetFile().then(() => {
        return store.state.chunkStore.datasetList
      })
    } else {
      return store.state.chunkStore.datasetList
    }
  },

  getNextFilename (side: string, focalBin = 0) {
    const zoomLevel = DataProvider.getZoomLevelObj(store.state.chunkStore.binWidth)

    if (focalBin === 0) {
      if (side === 'right') focalBin = store.state.chunkStore.currentLastBin
      else focalBin = store.state.chunkStore.currentFirstBin
    }

    if (side === 'right') {
      // Check if we are currently at the last file!
      if (focalBin >= zoomLevel.num_bins) {
        return 1
      }

      const lastFileId = Math.floor(focalBin / zoomLevel.bins_per_file) - 1
      return this.getFileName(lastFileId + 1)
    } else {
      // Do not load left if we're at the start
      if (focalBin <= 1) {
        return null
      }

      const lastFileId = Math.ceil(focalBin / zoomLevel.bins_per_file) - 1
      if ((lastFileId - 1) === 0) {
        return null
      }
      return this.getFileName(lastFileId - 1)
    }
  },

  getFileName (chunk: number) {
    return 'chunk.' + chunk + '.json'
  },

  getVCFFileName (name: string, chunk: number) {
    return 'vcfs/' + name + '/vcf_chunk.' + chunk + '.json'
  },

  getReadsFileName (chunkId: number) {
    return 'reads.' + this.getFileName(chunkId)
  },

  getFileIndexForBinPos (bin: number) {
    const zoomLevel = DataProvider.getZoomLevelObj(store.state.chunkStore.binWidth)
    const binnr = (bin <= 0 ? 0 : bin - 1)
    return Math.floor(binnr / zoomLevel.bins_per_file)
  },

  // getFirstBinOfChunkForBin (bin: number) {
  //   const zoomLevel = DataProvider.getZoomLevelObj(store.state.chunkStore.binWidth)
  //   const binnr = (bin <= 0 ? 0 : bin - 1)
  //   const chunkID = Math.floor(binnr / zoomLevel.bins_per_file)
  //   return chunkID * zoomLevel.bins_per_file + 1
  // },

  getLastFileIndex () {
    const zoomlevel = DataProvider.getZoomLevelObj(store.state.chunkStore.binWidth)

    return Math.floor((zoomlevel.num_bins - 1) / zoomlevel.bins_per_file)
  },

  storeFile (fileName: string, content: string, path: string) {
    return new Promise<void>((resolve, reject) => {
      const blob = new Blob([content], { type: 'text/tsv' })
      const file = new File([blob], fileName)
      ApiQueryService.uploadFile(path, file).then(() => {
        resolve()
      }).catch((error) => {
        reject(error)
      })
    })
  }

}

export default DataProvider

import { PanPosApiService } from '@/services/ApiService'

export const PanPosService = {
  getBinForPosition (dataset: string, path: string, position: string) {
    return PanPosApiService({
      url: dataset + '/' + path + '/' + position,
      method: 'get'
    })
  }
}

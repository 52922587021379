import { GraphStoreState, RootState } from '@/types/StoreTypes'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import * as PIXI from 'pixi.js'

const namespaced = true

const state: GraphStoreState = {
  isHightlighted: false,
  selectedHighlight: null,
  loading: false,
  contextMenu: { enabled: false, positionX: 0, positionY: 0, rawCoords: new PIXI.Point(), isColumnLink: false, vcfTracksVisible: false },
  selectionContextMenu: { enabled: false, positionX: 0, positionY: 0, rawCoords: new PIXI.Point() },
  scale: 1,
  placeholderVisible: false,
  qtlHighlight: { enabled: false, y: 0, height: 0 },
  areQTLsLoading: false,
  selectedQTL: null
  // leftmostBin: 0
}

const mutations: MutationTree<GraphStoreState> = {
  // ----------------
  // QTL
  // ----------------
  selectedQTL (state, payload) {
    state.selectedQTL = payload
  },

  areQTLsLoading (state, payload) {
    state.areQTLsLoading = payload
  },

  qtlHighlight (state, payload) {
    state.qtlHighlight = payload
  },

  // ----------------

  setIsHighlighted (state, payload) {
    state.isHightlighted = payload
  },

  setSelectedHighlight (state, payload) {
    state.selectedHighlight = payload
  },

  setLoading (state, payload) {
    state.loading = payload
  },

  contextMenu (state, payload) {
    state.contextMenu = payload
  },

  selectionContextMenu (state, payload) {
    state.selectionContextMenu = payload
  },

  setScale (state, payload) {
    state.scale = payload
  },

  setPlaceholderVisible (state, payload) {
    state.placeholderVisible = payload
  }

  // setLeftmostBin (state, payload) {
  //   state.leftmostBin = payload
  // }
}

const actions: ActionTree<GraphStoreState, RootState> = {
  setContextMenu ({ commit }, payload) {
    commit('contextMenu', payload)
  },
  setSelectionContextMenu ({ commit }, payload) {
    commit('selectionContextMenu', payload)
  }
}

const getters: GetterTree<GraphStoreState, RootState> = {}

export const GraphStore: Module<GraphStoreState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}

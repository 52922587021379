<template>
    <div id="graph-placeholder" :class="{ visible: visible }">
      <p>
        <strong>No visible track in the current view.</strong>
        <br>Please select at least one track in the <a @click="openTrackMenu()">Track Menu</a>, or move to another location.
      </p>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class GraphPlaceholder extends Vue {
  get visible () {
    return this.$store.state.graphStore.placeholderVisible
  }

  openTrackMenu () {
    this.$store.commit('pantoStore/setSideBar', { enabled: true, target: 'TrackMenu' })
  }
}
</script>

<style lang="scss">
#graph-placeholder {
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  text-align: center;

  &.visible {
    opacity: 1;
    pointer-events: all;
  }
}
</style>

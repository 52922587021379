import { PantoStoreState, RootState } from '@/types/StoreTypes'
import { PlotSelection, Selection, SideBarState, ClusterPlotState } from '@/types/Types'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import * as PIXI from 'pixi.js'
import store from '@/store'

const namespaced = true

const state: PantoStoreState = {
  // Alert
  alertState: { enabled: false, message: '', type: 'info', dismissible: true },
  // Slider
  sliderEnabled: false,
  sliderPosition: [],
  // Toolbar
  jumpMenuOpen: false,
  settingsMenuOpen: false,
  // SnapshotMenu
  snapshotMenuEnabled: false,
  // Legend
  legendVisible: false,
  // TrackMenu
  allGraphTracksSelected: true,
  allVCFTracksSelected: false,
  allReadTracksSelected: true,
  disabledGraphTracks: [],
  enabledVCFTracks: [],
  disabledReadTracks: [],
  // MetaMenu
  allMetaCategoriesEnabled: false,
  enabledMetaCategories: ['Name'],
  // JumpMenu
  targetPath: { name: '', position: '' },
  targetPangenomePosition: null,
  targetGene: null,
  // GeneMenu
  selectedGene: null,
  // SideBar
  sideBar: { enabled: false, target: null, wide: false },
  // Selector
  selection: null,
  selectedBins: { left: 0, right: 0 },
  // Dataset
  isDatasetLoaded: false,
  // Scatterplot
  scatterplotState: { enabled: false },
  plotSelection: null,
  // Phylogenetic Tree
  treeState: { enabled: false },
  treeToShow: null,
  // Login
  authenticated: false,
  // Misc
  overlayHovered: false,
  // QTL
  enabledQTLTracks: [],
  // ClusterPlot
  clusterPlotState: { enabled: false, clusters: null, plotColors: null },
  // Window
  focusedWindow: null
}

const mutations: MutationTree<PantoStoreState> = {
  // ------------------------------------------------
  // Alert
  // ------------------------------------------------
  setAlert (state: PantoStoreState, payload: { enabled: boolean, message: string, type: string, duration: number, dismissible: boolean }) {
    state.alertState = payload
  },

  // ------------------------------------------------
  // Window
  // ------------------------------------------------
  setFocusedWindow (state: PantoStoreState, payload: string) {
    state.focusedWindow = payload
  },

  // Use with any overlay (vue component or pixi object with z-index > 0)
  setOverlayHovered (state: PantoStoreState, payload: boolean) {
    state.overlayHovered = payload
  },

  // ------------------------------------------------
  // ClusterPlot
  // ------------------------------------------------
  setClusterPlotState (state: PantoStoreState, payload: ClusterPlotState) {
    state.clusterPlotState = payload
  },

  // ------------------------------------------------
  // QTL
  // ------------------------------------------------
  setEnabledQTLTracks (state: PantoStoreState, payload: Array<string>) {
    state.enabledQTLTracks = payload
  },

  // ------------------------------------------------
  // Scatterplot
  // ------------------------------------------------
  setScatterplotState (state: PantoStoreState, payload: { enabled: boolean }) {
    state.scatterplotState = payload
  },

  setPlotSelection (state: PantoStoreState, payload: PlotSelection) {
    state.plotSelection = payload
  },

  // ------------------------------------------------
  // Phylogenetic Tree
  // ------------------------------------------------
  setTreeState (state: PantoStoreState, payload: { enabled: boolean }) {
    state.treeState = payload
  },

  setTreeToShow (state: PantoStoreState, payload: string) {
    state.treeToShow = payload
  },

  // ------------------------------------------------
  // Dataset
  // ------------------------------------------------
  setDatasetLoaded (state: PantoStoreState, payload: boolean) {
    state.isDatasetLoaded = payload
  },

  // ------------------------------------------------
  // Slider
  // ------------------------------------------------
  setSliderEnabled (state: PantoStoreState, payload: boolean) {
    state.sliderEnabled = payload
  },

  setSliderPosition (state, payload) {
    state.sliderPosition = payload
  },

  // ------------------------------------------------
  // Toolbar
  // ------------------------------------------------
  setJumpMenuOpen (state: PantoStoreState, payload: boolean) {
    state.jumpMenuOpen = payload
  },

  setSettingsMenuOpen (state: PantoStoreState, payload: boolean) {
    state.settingsMenuOpen = payload
  },

  // ------------------------------------------------
  // SnapshotMenu
  // ------------------------------------------------
  setSnapshotMenuEnabled (state: PantoStoreState, payload: boolean) {
    state.snapshotMenuEnabled = payload
  },

  // ------------------------------------------------
  // Legend
  // ------------------------------------------------
  toggleLegend (state: PantoStoreState, payload: boolean) {
    state.legendVisible = payload === undefined ? !state.legendVisible : payload
  },

  setLegendVisible (state: PantoStoreState, payload: boolean) {
    state.legendVisible = payload
  },

  // ------------------------------------------------
  // SideBar
  // ------------------------------------------------
  setSideBar (state: PantoStoreState, payload: SideBarState) {
    state.sideBar = payload
  },

  // ------------------------------------------------
  // JumpMenu
  // ------------------------------------------------
  setTargetPath (state: PantoStoreState, payload: { name: string, position: string }) {
    state.targetPath = payload
  },

  setTargetPangenomePosition (state: PantoStoreState, payload: number) {
    state.targetPangenomePosition = payload
  },

  setTargetGene (state: PantoStoreState, payload: string) {
    state.targetGene = payload
  },

  // ------------------------------------------------
  // MetaMenu
  // ------------------------------------------------
  setAllMetaCategoriesEnabled (state: PantoStoreState, payload: boolean) {
    state.allMetaCategoriesEnabled = payload
  },

  setEnabledMetaCategories (state: PantoStoreState, payload: Array<string>) {
    state.enabledMetaCategories = payload
  },

  addEnabledMetaCategories (state: PantoStoreState, payload: string) {
    state.enabledMetaCategories.push(payload)
  },

  // ------------------------------------------------
  // TrackMenu
  // ------------------------------------------------
  setAllGraphTracksSelected (state: PantoStoreState, payload: boolean) {
    state.allGraphTracksSelected = payload
  },

  setAllVCFTracksSelected (state: PantoStoreState, payload: boolean) {
    state.allVCFTracksSelected = payload
  },

  setAllReadTracksSelected (state: PantoStoreState, payload: boolean) {
    state.allReadTracksSelected = payload
  },

  setDisabledGraphTracks (state: PantoStoreState, payload: Array<string>) {
    state.disabledGraphTracks = payload
  },

  setEnabledVCFTracks (state: PantoStoreState, payload: Array<string>) {
    state.enabledVCFTracks = payload
  },

  setDisabledReadTracks (state: PantoStoreState, payload: Array<string>) {
    state.disabledReadTracks = payload
  },

  // ------------------------------------------------
  // GeneMenu
  // ------------------------------------------------
  setSelectedGene (state: PantoStoreState, payload: { id: string, assembly: string } | null) {
    state.selectedGene = payload
  },

  // ------------------------------------------------
  // Selector
  // ------------------------------------------------
  selection (state: PantoStoreState, payload: Selection) {
    state.selection = payload
  },

  setSelectedBins (state: PantoStoreState, payload: { left: number, right: number }) {
    state.selectedBins = payload
  },

  // ------------------------------------------------
  // Login
  // ------------------------------------------------
  setAuthenticated (state: PantoStoreState, payload: boolean) {
    state.authenticated = payload
  }
}

const actions: ActionTree<PantoStoreState, RootState> = {
  // ------------------------------------------------
  // MetaMenu / Tree
  // ------------------------------------------------
  toggleAllCategories () {
    if (state.allMetaCategoriesEnabled) {
      state.enabledMetaCategories = [...store.state.metaStore.metaDataCategories]
    } else {
      state.enabledMetaCategories = ['Name']
    }
  },

  toggleCategory ({ state }, category: string) {
    if (state.enabledMetaCategories.includes(category)) {
      state.enabledMetaCategories = state.enabledMetaCategories.filter((e) => e !== category)
    } else {
      state.enabledMetaCategories.push(category)
    }
    state.allMetaCategoriesEnabled = state.enabledMetaCategories.length === store.state.metaStore.metaDataCategories.length
  },

  // ------------------------------------------------
  // SideBar
  // ------------------------------------------------
  setSideBarAsync ({ commit }, payload: { enabled: boolean, target: string | null, wide: boolean | null, }): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        commit('setSideBar', payload)
        resolve()
      } catch (error) {
        reject(error)
      }
    })
  },

  // ------------------------------------------------
  // Selector
  // ------------------------------------------------
  select (context, payload: Selection) {
    context.commit('selection', payload)
  },

  // ------------------------------------------------
  // JumpMenu
  // ------------------------------------------------
  jumpToPath ({ commit }, targetPath) {
    commit('setTargetPath', targetPath)
  },

  jumpToPangenomePosition ({ commit }, targetPangenomePosition) {
    commit('setTargetPangenomePosition', targetPangenomePosition)
  },

  jumpToGene ({ commit }, targetGene) {
    commit('setTargetGene', targetGene)
  }

}

const getters: GetterTree<PantoStoreState, RootState> = {
  // ------------------------------------------------
  // MetaMenu / Tree
  // ------------------------------------------------
  isMetaCategoryEnabled: (state) => (category: string) => {
    return state.enabledMetaCategories.includes(category)
  },

  // ------------------------------------------------
  // Selector
  // ------------------------------------------------
  selection (state) {
    return state.selection
  },

  // ------------------------------------------------
  // Login
  // ------------------------------------------------
  isAuthenticated (state) {
    return state.authenticated
  }
}

export const PantoStore: Module<PantoStoreState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}

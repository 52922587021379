import Graph from '@/graph/Graph'
import DataProvider from './DataProvider'
import store from '@/store'

export class ClusterService {
  // Clusterize the graph tracks based on the selected column X coordinate
  public static clusterizeGraphColumn (selectedColumnX: number) {
    const binInfo = Graph.getBinInfoForRawX(selectedColumnX)
    let cov: number | null = null
    let inv: number | null = null

    // Initialize an empty Record to store the clusters
    const clusters: Record<string, string[]> = {}

    // --- Clusterize by cov/inv values ---
    store.getters['chunkStore/getVisibleTracks'].graphTracks.forEach((trackName: string) => {
      if (binInfo.type === 'bin') {
        // selected column is a bin containing cov/inv data
        const bin = binInfo.binNumber
        const fileIdx = DataProvider.getFileIndexForBinPos(bin)
        const chunk = store.state.chunkStore.cachedChunks[fileIdx]
        const relativePosInChunk = bin - chunk.firstBin
        cov = chunk.tracks[trackName].covs[relativePosInChunk]
        inv = chunk.tracks[trackName].invs[relativePosInChunk]
      } else if (binInfo.type === 'link') {
        // selected column is a link column, cluster tracks by presence/absence of link
        const column = Graph.getColForX(selectedColumnX)
        const linkId = store.state.metaStore.columnInfo[column].linkId
        const link = store.state.metaStore.linkInfo[linkId]
        cov = link.paths.includes(trackName) ? 1 : 0
      }

      // Determine the cluster name based on cov/inv values
      let clusterName = ''
      if (inv && inv > 0) {
        clusterName = 'inverted'
      } else if (cov === 0) {
        clusterName = 'absent'
      } else if (cov && cov > 0) {
        clusterName = 'present'
      } else if (cov && cov > 1) {
        clusterName = 'duplicated'
      }

      // Add the track to its corresponding cluster
      if (!clusters[clusterName]) {
        clusters[clusterName] = []
      }
      clusters[clusterName].push(trackName)
    })

    return clusters
  }

  // Clusterize the VCF tracks based on the selected column X coordinate
  public static clusterizeVCFColumn (selectedColumnX: number) {
    // --- Clusterize by genotype ---
    const col = Graph.getColForX(selectedColumnX)
    const binInfo = Graph.getBinInfoForRawX(selectedColumnX)
    const bin = binInfo.binNumber
    const fileIdx = DataProvider.getFileIndexForBinPos(bin)
    const chunk = store.state.chunkStore.cachedChunks[fileIdx]
    let clusterName = ''

    // Initialize an empty Record to store the clusters
    const clusters: Record<string, string[]> = {}

    for (const trackName of store.getters['chunkStore/getVisibleTracks'].vcfTracks) {
      let found = false
      if (!store.state.chunkStore.vcfTracks.get(trackName).sum) {
        if (chunk.vcfTracks[trackName]) {
          for (const varData of chunk.vcfTracks[trackName].b) {
            if (varData.b === Graph.getBinInfoForColumn(col).binNumber) {
              for (const variant of varData.v) {
                if (!String(variant.g).includes('.')) {
                  // clusterName = 'Cov = ' + variant.gt.charAt(0) // Cluster name based on the first character
                  clusterName = variant.g as string

                  if (typeof variant.g === 'number') {
                    if (variant.g === 0) clusterName = 'ref'
                    else if (variant.g === 1) clusterName = 'het (0/1)'
                    else if (variant.g === 2) clusterName = 'hom allele 1'
                  } else if (typeof variant.g === 'string') {
                    const gts = String(variant.g).split('/')
                    if (variant.g === '0/0') clusterName = 'ref'
                    else if (gts[0] === gts[1] && gts[0] !== '.') clusterName = 'hom allele ' + gts[0]
                    else clusterName = 'het (' + Math.min(parseInt(gts[0]), parseInt(gts[1])) + '/' + Math.max(parseInt(gts[0]), parseInt(gts[1])) + ')'
                  }

                  // if (variant.g === '0/0') {
                  //   clusterName = 'ref'
                  // } else if (variant.g === 1) {
                  //   clusterName = 'het'
                  // } else if (variant.g === 2) {
                  //   clusterName = 'hom alt'
                  // }
                  found = true
                  // Add the track to its corresponding cluster
                  if (!clusters[clusterName]) {
                    clusters[clusterName] = []
                  }
                  clusters[clusterName].push(trackName)
                  break
                }
              }
            }
            if (found) break
          }
        }
      }
    }

    return clusters
  }
}

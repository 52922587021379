import { ApiQueryService } from '@/services/ApiQueryService'
import { AuthStoreState, RootState } from '@/types/StoreTypes'
import { UserLogin } from '@/types/Types'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import router from '@/router'

const namespaced = true

const state: AuthStoreState = {
  token: ''
}

const mutations: MutationTree<AuthStoreState> = {
  setToken (state, token) {
    state.token = token
  }
}

const actions: ActionTree<AuthStoreState, RootState> = {
  authRequest ({ commit, dispatch }, user: UserLogin) {
    return new Promise<void>((resolve, reject) => { // The Promise used for router redirect in login
      ApiQueryService.login(user).then((response: any) => {
        const token = response.data.access_token
        commit('setToken', token)
        resolve()
      }).catch((err: any) => {
        dispatch('authLogout')
        reject(err)
      })
    })
  },
  authLogout ({ commit }) {
    return new Promise<void>((resolve) => {
      commit('setToken', '')
      router.push({ name: 'Login' })
      resolve()
    })
  }
}

const getters: GetterTree<AuthStoreState, RootState> = {
  isAuthenticated: (state) => !!state.token
}

export const AuthStore: Module<AuthStoreState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}

<template>
  <div id="toolbar">
    <v-container fluid>
      <v-row align="baseline">
        <!-- Dataset selection -->
        <v-col>
          <v-select
            hide-details
            :items=datasets
            @change="onDatasetChange"
            v-model="dataset"
            item-text="dataset_name"
            item-value="dataset_path"
            :label = "$t('toolbar.dataset')">
          </v-select>
        </v-col>
        <!-- Bin width -->
        <v-col>
          <v-select
            hide-details
            :items=binWidths
            @change="onBinWidthChange"
            v-model="selectedBinWidth"
            :label = "$t('toolbar.bin-width')">
          </v-select>
        </v-col>
        <!-- Sort by Clustering -->
        <v-col v-if="false">
          <v-select
            v-model="clusteringSortOption"
            :items=clusteringSortItems
            @change="onClusteringSortChange"
            :label = "$t('toolbar.sort-by-clustering')"
            :disabled="true"
            hide-details
          >
          </v-select>
        </v-col>
        <!-- Sort by Metadata -->
        <v-col>
          <v-select
            hide-details
            :items=sortOptions
            @change="onSortChange"
            v-model="selectedSortOption"
            :label = "$t('toolbar.sorting')">
          </v-select>
        </v-col>
        <!-- Color by Metadata -->
        <v-col>
          <v-select
            hide-details
            :items=metadataColors
            @change="redrawKeepPos"
            v-model="selectedMetadataToColor"
            :label =  "$t('toolbar.coloring')">
          </v-select>
        </v-col>
        <!-- Sort order -->
        <v-col class="shrink">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                fab
                small
                text
                @click="onSortOrderChange"
                v-on="on">
                <v-icon>
                  {{ sortOrderIcon }}
                </v-icon>
              </v-btn>
            </template>
            <span> {{$t('toolbar.sort-order')}}</span>
          </v-tooltip>
        </v-col>
        <!-- Jump menu -->
        <v-col class="shrink">
          <v-btn elevation="0" outlined @click="openJumpMenu()">
            <v-icon>mdi-alpha-j-box-outline</v-icon>
            <span class="text-body-2 text-capitalize">{{$t('jump-menu.tooltip')}}</span>
          </v-btn>
        </v-col>
        <!-- Meta Menu > button -->
        <v-col class="shrink">
          <v-btn elevation="0" outlined @click="openMetaMenu()">
            <v-icon>mdi-alpha-m-box-outline</v-icon>
            <span class="text-body-2 text-capitalize">{{$t('meta-menu.tooltip')}}</span>
          </v-btn>
        </v-col>
        <!-- Track Menu > button -->
        <v-col class="shrink">
          <v-btn elevation="0" outlined @click="openTrackMenu()">
            <v-icon>mdi-alpha-t-box-outline</v-icon>
            <span class="text-body-2 text-capitalize">{{$t('track-menu.tooltip')}}</span>
          </v-btn>
        </v-col>
        <!-- Scatterplot > button -->
        <v-col class="shrink">
          <v-btn elevation="0" outlined @click="openScatterplot()">
            <v-icon>mdi-chart-scatter-plot</v-icon>
            <span class="text-body-2 text-capitalize">{{$t('track-menu.scatterplot')}}</span>
          </v-btn>
        </v-col>
        <!-- Phylo Tree > button -->
        <v-col class="shrink">
          <v-btn elevation="0" outlined @click="openTree()">
            <v-icon>mdi-file-tree</v-icon>
            <span class="text-body-2 text-capitalize">Tree</span>
          </v-btn>
        </v-col>
        <!-- QTL Legend > button -->
        <v-col class="shrink" v-if="qtlMetadata">
          <v-btn elevation="0" outlined @click="openQTLLegend()">
            <v-icon>mdi-alpha-q-box-outline</v-icon>
            <span class="text-body-2 text-capitalize">QTL</span>
          </v-btn>
        </v-col>
        <!-- Settings -->
        <v-col class="shrink">
          <v-menu
            offset-x
            offset-y
            :close-on-content-click="false"
            v-model="settingsMenuOpen">
            <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn icon v-bind="attrs" v-on="{...tooltip, ...menu}">
                    <v-icon>mdi-cog</v-icon>
                  </v-btn>
                </template>
                <span>{{$t('toolbar.settings')}}</span>
              </v-tooltip>
            </template>
            <v-list>
                <v-list-item>
                <v-text-field
                  :rules="[rules.greatereq0, rules.smaller1]"
                  hide-details
                  v-model.lazy="covFraction"
                  @change="filterTracksByCoverage"
                  :label="$t('settings-menu.fraction-bin-cov')">
                </v-text-field>
              </v-list-item>
              <v-list-item>
                <v-checkbox
                  hide-details
                  v-model="drawInversions"
                  @change="redrawKeepPos"
                  :label="$t('settings-menu.draw-inversions')">
                </v-checkbox>
              </v-list-item>
              <v-list-item>
                <v-checkbox
                  hide-details
                  v-model="drawDuplications"
                  @change="redrawKeepPos"
                  :label="$t('settings-menu.mark-duplicates')">
                </v-checkbox>
              </v-list-item>
              <v-list-item>
                <v-list-item-subtitle>
                  {{$t('settings-menu.layout-options')}}
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <v-checkbox
                  hide-details
                  v-model="drawLinks"
                  @change="switchDrawLinks"
                  :label="$t('settings-menu.draw-links')">
                </v-checkbox>
              </v-list-item>
              <v-list-item>
                <v-checkbox
                  hide-details
                  :disabled="denseView"
                  v-model="drawCellMargin"
                  @change="redrawKeepPos"
                  :label="$t('settings-menu.draw-margin')">
                </v-checkbox>
              </v-list-item>
              <v-list-item>
                <v-checkbox
                  hide-details
                  v-model="denseView"
                  @change="switchDenseView"
                  :label="$t('settings-menu.dense-view')">
                </v-checkbox>
              </v-list-item>
              <v-list-item>
                <v-list-item-subtitle>
                  {{$t('settings-menu.advanced-options')}}
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <v-text-field
                  type="number"
                  :rules="[rules.greater0, rules.number]"
                  hide-details
                  v-model.number="nrChunksToLoad"
                  :label="$t('settings-menu.nr-chunks-toload')">
                </v-text-field>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-container>

    <!-- Track banner -->
    <!-- TODO: move to BottomBar component -->
    <div v-if="nbTracks" class="track-banner"><strong>{{ nbTracks }}</strong> / {{ [...$store.state.chunkStore.rawGraphTracks.keys()].length }} paths</div>

  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import DataProvider from '@/services/DataProvider'

@Component
export default class ToolBar extends Vue {
  rules = {
    pathRequired: (v: string) => !!v || 'Path is required',
    posRequired: (v: string) => !!v || 'Position is required',
    required: (v: string) => !!v || 'Value required',
    number: (v: string) => /^\d*$/.test(v) || 'Position not a number',
    greater0: (v: string) => Number(v) > 0 || 'Position must be > 0',
    greatereq0: (v: string) => Number(v) >= 0 || 'Fraction must be >= 0',
    smaller1: (v: string) => Number(v) <= 1 || 'Fraction must be <= 1',
    tooLarge: (v: string) => Number(v) <= this.currentMaxBin * this.selectedBinWidth || 'Position too large'
  }

  // -------------------

  get datasets () {
    return DataProvider.getAvailableDatasets()
  }

  get dataset () {
    return this.$store.state.chunkStore.dataset
  }

  set dataset (value) {
    this.$store.commit('chunkStore/setDataset', value)
  }

  get binWidths () {
    return DataProvider.getAvailableBinWidths()
  }

  get lastBinWidth () {
    return this.$store.state.chunkStore.lastBinWidth
  }

  set lastBinWidth (value) {
    this.$store.commit('chunkStore/setLastBinWidth', value)
  }

  get selectedBinWidth () {
    return this.$store.state.chunkStore.binWidth
  }

  set selectedBinWidth (value) {
    this.lastBinWidth = this.$store.state.chunkStore.binWidth
    this.$store.commit('chunkStore/setBinWidth', value)
  }

  get sortOptions () {
    return this.$store.getters['metaStore/getAvailableSortOptions']
  }

  get sortOrder () {
    return this.$store.state.metaStore.selectedSortOrder
  }

  set sortOrder (value) {
    this.$store.commit('metaStore/setSelectedSortOrder', value)
  }

  clusteringSortItems = [
    { text: 'Cluster 1', value: 'cluster1' },
    { text: 'Cluster 2', value: 'cluster2' },
    { text: 'Cluster 3', value: 'cluster3' }
  ]

  // get clusteringSortItems () {
  //   // return this.$store.state.metaStore.clusteringSortItems
  // }

  get clusteringSortOption () {
    return this.$store.state.metaStore.clusteringSortOption
  }

  set clusteringSortOption (value) {
    this.$store.commit('metaStore/setClusteringSortOption', value)
  }

  get selectedSortOption () {
    return this.$store.state.metaStore.selectedSortOption
  }

  set selectedSortOption (value) {
    this.$store.commit('metaStore/setSelectedSortOption', value)
  }

  get sortOrderIcon () {
    if (this.sortOrder === 'asc') {
      return 'mdi-sort-alphabetical-ascending'
    } else {
      return 'mdi-sort-alphabetical-descending'
    }
  }

  get metadataColors () {
    return this.$store.state.metaStore.metaDataCategories.filter((category: string) => category !== 'Name')
  }

  get selectedMetadataToColor () {
    return this.$store.state.metaStore.selectedMetadataToColor
  }

  set selectedMetadataToColor (value) {
    this.$store.commit('metaStore/setSelectedMetadataToColor', value)
  }

  get jumpMenuOpen (): boolean {
    return this.$store.state.pantoStore.jumpMenuOpen
  }

  set jumpMenuOpen (value: boolean) {
    this.$store.commit('pantoStore/setJumpMenuOpen', value)
  }

  get settingsMenuOpen (): boolean {
    return this.$store.state.pantoStore.settingsMenuOpen
  }

  set settingsMenuOpen (value: boolean) {
    this.$store.commit('pantoStore/setSettingsMenuOpen', value)
  }

  get nbTracks () {
    return this.$store.state.chunkStore.nbTracks
  }

  set nbTracks (value) {
    this.$store.commit('chunkStore/nbTracks', value)
  }

  get currentMaxBin (): number {
    return this.$store.state.chunkStore.currentMaxBin
  }

  get tracks () {
    return [...this.$store.state.chunkStore.trackMap.keys()]
  }

  get selectedPanPos () {
    return this.$store.state.metaStore.selectedPanPosition
  }

  set selectedPanPos (value) {
    this.$store.commit('metaStore/setSelectedPanPosition', value)
  }

  get genes () {
    return Object.keys(this.$store.state.metaStore.geneInfo)
  }

  get selectedGene () {
    return this.$store.state.metaStore.selectedGene
  }

  set selectedGene (value) {
    this.$store.commit('metaStore/setSelectedGene', value)
  }

  get covFraction () {
    return this.$store.state.metaStore.covFraction
  }

  set covFraction (value) {
    this.$store.commit('metaStore/setLastCovFraction', this.$store.state.metaStore.covFraction)
    this.$store.commit('metaStore/setCovFraction', value)
  }

  get drawInversions () {
    return this.$store.state.metaStore.drawInversions
  }

  set drawInversions (value) {
    this.$store.commit('metaStore/setDrawInversions', value)
  }

  get drawDuplications () {
    return this.$store.state.metaStore.drawDuplications
  }

  set drawDuplications (value) {
    this.$store.commit('metaStore/setDrawDuplications', value)
  }

  get drawLinks () {
    return this.$store.state.metaStore.drawLinks
  }

  set drawLinks (value) {
    this.$store.commit('metaStore/setDrawLinks', value)
  }

  get drawCellMargin () {
    return this.$store.state.metaStore.drawCellMargin
  }

  set drawCellMargin (value) {
    this.$store.commit('metaStore/setDrawCellMargin', value)
  }

  get denseView () {
    return this.$store.state.metaStore.denseView
  }

  set denseView (value) {
    this.$store.commit('metaStore/setDenseView', value)
  }

  get nrChunksToLoad () {
    return this.$store.state.chunkStore.nrChunksToLoad
  }

  set nrChunksToLoad (value) {
    this.$store.commit('chunkStore/setNrChunksToLoad', value)
  }

  get selectedPath () {
    return this.$store.state.metaStore.selectedPath
  }

  set selectedPath (value) {
    this.$store.commit('metaStore/setSelectedPath', value)
  }

  get selectedPathPos () {
    return this.$store.state.metaStore.selectedPathPosition
  }

  set selectedPathPos (value) {
    this.$store.commit('metaStore/setSelectedPathPosition', value)
  }

  get qtlMetadata () {
    return this.$store.state.metaStore.qtlMetadata
  }

  // -------------------

  onDatasetChange () {
    this.$emit('datasetChanged', this.dataset)
  }

  onBinWidthChange () {
    this.$emit('binWidthChanged', this.selectedBinWidth)
  }

  onClusteringSortChange () {
    this.$emit('clusteringSortChanged', this.clusteringSortOption)
  }

  onSortChange () {
    this.$emit('sortChanged', this.selectedSortOption)
  }

  onSortOrderChange () {
    if (this.sortOrder === 'asc') {
      this.sortOrder = 'desc'
    } else {
      this.sortOrder = 'asc'
    }
    this.$emit('sortOrderChanged', this.sortOrder)
  }

  redrawKeepPos () {
    this.$emit('redrawKeepPos')
  }

  // -----------------
  // Sidebar functions
  // -----------------

  openJumpMenu () {
    this.$emit('openJumpMenu')
  }

  openMetaMenu () {
    this.$emit('openMetaMenu')
  }

  openTrackMenu () {
    this.$emit('openTrackMenu')
  }

  // -----------------

  openScatterplot () {
    this.$emit('openScatterplot')
  }

  openQTLLegend () {
    this.$emit('openQTLLegend')
  }

  openTree () {
    this.$emit('openTree')
  }

  // -----------------

  switchDrawLinks () {
    this.$emit('switchDrawLinks')
  }

  switchDenseView () {
    this.$emit('switchDenseView')
  }

  filterTracksByCoverage () {
    this.$emit('filterTracksByCoverage')
  }
}
</script>

<style lang="scss" scoped>
#toolbar {
  margin-top: 60px;
  background-color: #f3f3f3;
  border-bottom: 1px solid #d8d8d8;
  z-index: 2;
  position: relative;
}

.track-banner{
  height: 70px;
  padding: 20px 40px;
  position: fixed;
  z-index: 3;
  right: 20px;
  bottom: 0;
  white-space: nowrap;
  text-align: center;
  line-height: 34px;
}
</style>

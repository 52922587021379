<template>
  <div
    id="selection-overlay"
    ref="selectionOverlay"
    :class="{ enabled }"
    v-show="enabled"
  ></div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import Selecto from 'selecto'

@Component
export default class RegionSelection extends Vue {
  public enabled = false

  mounted () {
    const selecto = new Selecto({
      // The container to add a selection element
      container: this.$refs.selectionOverlay as HTMLElement,
      // Whether to select by click (default: true)
      selectByClick: true,
      // Whether to select from the target inside (default: true)
      selectFromInside: true,
      // After the select, whether to select the next target with the selected target (deselected if the target is selected again).
      continueSelect: false,
      // Determines which key to continue selecting the next target via keydown and keyup.
      toggleContinueSelect: 'shift',
      // The container for keydown and keyup events
      keyContainer: window,
      // The rate at which the target overlaps the drag area to be selected. (default: 100)
      hitRate: 100
    })

    selecto.on('selectEnd', e => {
      this.$store.dispatch('pantoStore/select', e.rect)
    })

    // Listen for the shift key to be pressed
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Shift') {
        this.enabled = true
      }
    })

    // Listen for the shift key to be released
    document.addEventListener('keyup', (e) => {
      if (e.key === 'Shift') {
        this.enabled = false
      }
    })
  }
}
</script>

<style lang="scss" scoped>
#selection-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;

  &.enabled {
    cursor: crosshair;
  }
}
</style>

<template>
    <div class="tooltip" :class="{ visible: showTooltip }">
      {{ tooltipText }}
    </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class Tooltip extends Vue {
    @Prop({ default: '' }) tooltipText!: string
    @Prop({ default: false }) showTooltip!: boolean

    mounted () {
      document.addEventListener('mousemove', this.updateCoords)
    }

    updateCoords (e: MouseEvent) {
      if (!this.showTooltip) return
      const tooltip = this.$el as HTMLElement
      tooltip.style.top = e.clientY + 10 + 'px'
      tooltip.style.left = e.clientX + 10 + 'px'
    }
}
</script>

<style scoped lang="scss">
.tooltip {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #cacaca;
  color: #121212;
  padding: 8px;
  border-radius: 5px;
  font-size: 14px;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;

  &.visible {
    opacity: 1;
    pointer-events: auto;
  }
}
</style>

<template>
  <div class="scatterplot">
    <!-- Toolbar -->
    <div class="toolbar">
      <v-select
        class="select"
        label="Categories"
        :items="plotCategories"
        v-model="selectedCategory"
        @change="renderPlot"
      ></v-select>
    </div>
    <!-- Selection btn -->
    <v-btn
      class="btn-show-samples"
      outlined
      @click="showSamples"
    >{{ pointsSelected ? 'Browse ' + selectedVCFTracks.length + ' matching samples' : 'Browse all matching samples' }}</v-btn>
    <!-- Scatterplot -->
    <div class="plot-container" ref="plotContainer"></div>
    <!-- Tips -->
    <div class="tips">Double click to reset view/selection</div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Watch } from 'vue-property-decorator'
import Plotly from 'plotly.js-dist-min'
import PlotService from '@/services/PlotService'
import { PlotSelection } from '@/types/Types'

interface PlotData {
  name: string
  x: number[]
  y: number[]
  mode: string
  type: string
  hovertemplate?: string
  text?: string[]
  opacity?: number
  selectedpoints?: number[]
}

@Component
export default class Scatterplot extends Vue {
  plotContainer: any
  selectedVCFTracks: string[] = []
  pointsSelected = false
  selectedCategory = 'Biological status'

  plotCategories = [
    'Biological status',
    'Country',
    'MG',
    'Company',
    'Group'
  ]

  data: PlotData[] = []

  layout = {
    title: 'PC1 / PC2',
    xaxis: {
      title: 'PC1',
      showgrid: false
    },
    yaxis: {
      title: 'PC2',
      showline: false
    },
    // Set box selection as default
    dragmode: 'select',
    selections: []
  }

  // ------------------------------------------------
  // Getters / setters
  // ------------------------------------------------

  get scatterplotState () {
    return this.$store.state.pantoStore.scatterplotState
  }

  set scatterplotState ({ enabled }) {
    this.$store.commit('pantoStore/setScatterplotState', { enabled })
  }

  get isDatasetLoaded () {
    return this.$store.state.pantoStore.isDatasetLoaded
  }

  // ------------------------------------------------
  // Watchers
  // ------------------------------------------------

  @Watch('isDatasetLoaded')
  onDatasetLoadedChange () {
    // Get data
    PlotService.getPlotData().then((data) => {
      data.forEach((d: any) => {
        // if this.data does not contain the category, add it
        if (!this.data.some((e) => e.name === d[this.selectedCategory])) {
          this.data.push({
            name: d[this.selectedCategory],
            x: [d.PC1],
            y: [d.PC2],
            mode: 'markers',
            type: 'scatter',
            hovertemplate: '%{text}',
            text: [d.Taxa],
            opacity: 0.8,
            selectedpoints: []
          })
        } else {
          // if this.data contains the category, update it
          const index = this.data.findIndex((e) => e.name === d[this.selectedCategory])
          this.data[index].x.push(d.PC1)
          this.data[index].y.push(d.PC2)
          this.data[index].text?.push(d.Taxa)
        }
      })
    })
  }

  @Watch('scatterplotState')
  onScatterplotChange (val: { enabled: boolean }) {
    if (val.enabled) {
      this.renderPlot()
    }
  }

  // ------------------------------------------------
  // Methods
  // ------------------------------------------------

  mounted () {
    this.plotContainer = this.$refs.plotContainer as HTMLElement
  }

  renderPlot () {
    // Reset selection
    this.pointsSelected = false
    this.layout.selections = []
    this.data = []

    // Create plot
    Plotly.purge(this.plotContainer)
    Plotly.newPlot(this.plotContainer, this.data, this.layout)

    // Reset selection on double click
    // NOTE: not working with Plotly's double click event
    this.plotContainer.on('plotly_relayout', (e: any) => {
      if (e.selections && e.selections.length === 0) {
        this.pointsSelected = false
        this.selectedVCFTracks = []
      }
    })

    this.plotContainer.on('plotly_selected', (e: { points: any[] }) => {
      if (e !== undefined && e.points !== undefined && e.points.length > 0) {
        const selection: PlotSelection = {
          taxas: []
        }

        // For each point in the selection, get the taxa name
        e.points.forEach((point: any) => {
          selection.taxas.push(point.data.text[point.pointIndex])
        })

        // ---------------------------
        // Update the disabled vcf tracks
        // TODO: about VCFs, we should invert the logic: instead of disabling the ones that are not in the selection, we should enable the ones that are in the selection
        // because by default they should all be disabled, then only be enabled when the user selects them (via the scatterplot or the track menu)
        const vcfTracks = this.$store.state.chunkStore.vcfTracks
        const selectedTracks: string[] = []
        vcfTracks.forEach((track: any) => {
          if (selection.taxas.includes(track.label)) {
            selectedTracks.push(track.label)
          }
        })
        // ---------------------------
        this.selectedVCFTracks = selectedTracks
        this.pointsSelected = true
      }
    })

    PlotService.getPlotData().then((data) => {
      data.forEach((d: any) => {
        // if this.data does not contain the category, add it
        if (!this.data.some((e) => e.name === d[this.selectedCategory])) {
          this.data.push({
            name: d[this.selectedCategory],
            x: [d.PC1],
            y: [d.PC2],
            mode: 'markers',
            type: 'scatter',
            hovertemplate: '%{text}',
            text: [d.Taxa],
            opacity: 0.8
          })
        } else {
          // if this.data contains the category, update it
          const index = this.data.findIndex((e) => e.name === d[this.selectedCategory])
          this.data[index].x.push(d.PC1)
          this.data[index].y.push(d.PC2)
          this.data[index].text?.push(d.Taxa)
        }
      })
      Plotly.update(this.plotContainer, this.data, this.layout)
    })
  }

  showSamples () {
    if (this.pointsSelected) {
      this.$store.commit('pantoStore/setEnabledVCFTracks', this.selectedVCFTracks)
    } else {
      const selectedTracks: string[] = []
      this.data.forEach((plotData) => {
        plotData.text?.forEach((taxa) => {
          if (this.$store.state.chunkStore.vcfTracks.has(taxa)) {
            selectedTracks.push(taxa)
          }
        })
      })
      this.$store.commit('pantoStore/setEnabledVCFTracks', selectedTracks)
    }
    this.$store.commit('pantoStore/setScatterplotState', { enabled: false })
  }

  resize (size: { width: number, height: number }) {
    Plotly.relayout(this.plotContainer, {
      width: size.width - 20,
      height: size.height - 40
    })
  }
}
</script>

<style lang="scss" scoped>
.scatterplot {
  width: 100%;
  height: 100%;
  position: relative;
}

.plot-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
}

.toolbar {
  margin: $space-l 0 0 $space-l;
  position: absolute;
  z-index: 100;
}

.select {
  width: 200px;
  z-index: 1001;
}

.tips {
  position: absolute;
  bottom: $space-s;
  left: $space-s;
  color: rgb(104, 104, 104);
  font-style: italic;
  z-index: 100;
}

.btn-show-samples {
  position: absolute;
  top: 40px;
  right: $space-l;
  z-index: 100;
}
</style>

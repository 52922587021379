import store from '@/store'
import axios from 'axios'

const localBaseURL = process.env.BASE_URL
const panPosBaseURL = process.env.VUE_APP_POS_API_URL
const apiBaseURL = process.env.VUE_APP_API_URL

const LocalApiService = axios.create({
  baseURL: localBaseURL
})

const PanPosApiService = axios.create({
  baseURL: panPosBaseURL
})

const ApiService = axios.create({
  baseURL: apiBaseURL
})

ApiService.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (typeof error === 'undefined' || error === null) {
      return
    }

    // On network issues this will be undefined
    if (error.response) {
      const stat = error.response.status

      switch (stat) {
        case 500:
          break
        case 401:
          store.dispatch('authStore/authLogout')
          break
        default:
          break
      }

      return Promise.reject(error.response)
    } else {
      // show error
    }

    return Promise.reject(error)
  }
)

ApiService.interceptors.request.use(
  (config) => {
    const token = store.state.authStore.token

    if (token && config?.headers) {
      config.headers.Authorization = `Bearer ${token}`
    }

    return config
  },

  (error) => {
    // console.log("reqE", error)
    return Promise.reject(error)
  }
)

PanPosApiService.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (typeof error === 'undefined' || error === null) {
      return
    }

    // On network issues this will be undefined
    if (error.response) {
      const stat = error.response.status

      switch (stat) {
        case 500:
          break
        case 401:
          break
        default:
          break
      }

      return Promise.reject(error.response)
    } else {
      // show error
    }

    return Promise.reject(error)
  }
)

LocalApiService.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (typeof error === 'undefined' || error === null) {
      return
    }

    // On network issues this will be undefined
    if (error.response) {
      const stat = error.response.status

      switch (stat) {
        case 500:
          break
        case 401:
          break
        default:
          break
      }

      return Promise.reject(error.response)
    } else {
      // show error
    }

    return Promise.reject(error)
  }
)

export { LocalApiService, PanPosApiService, ApiService }

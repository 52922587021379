import { createPopper } from '@popperjs/core'
import PixiUtils from '@/graph/Graph'
import store from '@/store'
import PIXI from 'pixi.js'
import Config from '@/graph/Config'

let virtualElement: any = null
let tooltip: HTMLElement | null = null
let instance: any = null

const Tooltip = {

  init () {
    virtualElement = {
      getBoundingClientRect: this.generateGetBoundingClientRect()
    }

    // Create tooltip
    tooltip = document.createElement('div')
    tooltip.id = 'tooltip'
    tooltip.className = 'tooltip'
    tooltip.setAttribute('role', 'tooltip')
    document.body.appendChild(tooltip)

    if (virtualElement && tooltip) {
      instance = createPopper(virtualElement, tooltip, {
        placement: 'right-start',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [10, 20]
            }
          }
        ]
      })
    }
  },

  hide () {
    if (tooltip) {
      tooltip.style.visibility = 'hidden'
    }
  },

  show () {
    if (tooltip) {
      tooltip.style.visibility = 'visible'
    }
  },

  toggleWorldTooltip (event: any, pixiContainer: HTMLElement) {
    const containerId = pixiContainer.getAttribute('id')
    if (containerId && tooltip) {
      const containerRect = document.getElementById(containerId)?.getBoundingClientRect()
      virtualElement.getBoundingClientRect = this.generateGetBoundingClientRect(containerRect?.left + event.screen.x, containerRect?.top + event.screen.y)

      // Get genome if clicked inside the data-view
      let pathName = PixiUtils.getTrackNameByCoordinate(event.world.y)

      // Get cell info for a lnk or a bin
      const cellInfo = PixiUtils.getCellInfo(event.world.x, event.world.y)

      tooltip.innerHTML = ''
      if (!pathName && cellInfo === '') {
        // Hide if nothing would be displayed
        this.hide()
        // PixiUtils.removeHighlight()
      } else {
        if (pathName) {
          if (pathName.startsWith('TRB-')) {
            pathName = pathName.replace('Av1', '')
            // pathName = pathName.slice(4)
          }
          tooltip.innerHTML += pathName
        }
        if (cellInfo !== '') {
          if (pathName) tooltip.innerHTML += '</br>'
          tooltip.innerHTML += cellInfo
        }
      }

      instance.update()
    }
  },

  toggleMetaTooltip (coords: { screen: PIXI.Point, world: PIXI.Point }, relativeX: number, pixiContainer: HTMLElement, metaSortTooltipTarget: number) {
    const containerId = pixiContainer.getAttribute('id')
    if (containerId) {
      const containerRect = document.getElementById(containerId)?.getBoundingClientRect()
      if (containerRect) {
        virtualElement.getBoundingClientRect = this.generateGetBoundingClientRect(containerRect?.left + coords.screen.x, containerRect?.top + coords.screen.y)
      }

      const metaCategories = store.getters['metaStore/metaDataCategories']
      const metaColumn = Math.floor(relativeX / (Config.cellWidth + Config.cellMargin))

      // Note: the last entry in metaCategories is ALWAYS the track 'Name'
      if (coords.world.y <= 10 && tooltip && metaColumn >= 0 && metaColumn < metaCategories.length) {
        // If the mouse is in the sorting area then show the 'Sort by' tooltip
        const category = metaCategories[metaColumn]
        tooltip.innerHTML = 'Sort by ' + category
      } else {
        // Default tooltip showing track and metadata
        let trackName = PixiUtils.getTrackNameByCoordinate(coords.world.y)
        if (trackName !== '' && tooltip) {
          if (trackName.startsWith('TRB-')) {
            trackName = trackName.replace('Av1', '')
            // pathName = pathName.slice(4)
          }
          tooltip.innerHTML = trackName

          if (metaColumn >= 0 && metaColumn < metaCategories.length - 1) {
            let metaString = ''
            if (trackName in store.state.metaStore.metaData) {
              if (metaCategories) {
                metaString = store.state.metaStore.metaData[trackName][metaCategories[metaColumn]]
                if (metaString !== undefined && metaString !== null) {
                  tooltip.innerHTML += '</br>' + metaCategories[metaColumn] + ': ' + metaString
                } else {
                  tooltip.innerHTML += '</br><span style="color: grey;">' + metaCategories[metaColumn] + ': -</span>'
                }
              }
            } else {
              tooltip.innerHTML += '</br><span style="color: grey;">' + metaCategories[metaColumn] + ': -</span>'
            }
          }
        } else {
          this.hide()
        }
      }
      instance.update()
    }
  },

  generateGetBoundingClientRect (x = 0, y = 0) {
    return () => ({
      width: 0,
      height: 0,
      top: y,
      right: x,
      bottom: y,
      left: x
    })
  }
}

export default Tooltip

<template>
    <v-overlay fixed :class="{visible: loading}" class="circle-loader">
      <v-progress-circular indeterminate size="64" color="primary"/>
    </v-overlay>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Loader extends Vue {
  get loading () {
    return this.$store.state.graphStore.loading
  }

  set loading (value) {
    this.$store.commit('graphStore/setLoading', value)
  }
}
</script>

<style scoped>
.circle-loader {
  opacity: 0;
  pointer-events: none;
}
.visible {
  opacity: 1;
  pointer-events: inherit;
}
</style>

<template>
    <div
      id="sidebar"
      v-bind:class="{ visible: sideBar.enabled, wide: sideBar.wide }"
      @mouseover="overlayHovered = true"
      @mouseleave="overlayHovered = false"
    >
      <v-btn icon @click="close" class="btn-close" ref="closeBtn">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <JumpMenu v-if="sideBar.target == 'JumpMenu'"/>
      <MetaMenu v-if="sideBar.target == 'MetaMenu'"/>
      <TrackMenu v-if="sideBar.target == 'TrackMenu'"/>
      <GeneMenu v-if="sideBar.target == 'GeneMenu'"/>
      <QTLLegend v-if="sideBar.target == 'QTLLegend'"/>
      <QTLMenu v-if="sideBar.target == 'QTLMenu'"/>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { SideBarState } from '@/types/Types'

import JumpMenu from '@/components/JumpMenu.vue'
import MetaMenu from '@/components/MetaMenu.vue'
import TrackMenu from '@/components/TrackMenu.vue'
import GeneMenu from '@/components/GeneMenu.vue'
import QTLLegend from '@/components/QTLLegend.vue'
import QTLMenu from '@/components/QTLMenu.vue'

@Component({
  components: {
    JumpMenu,
    MetaMenu,
    TrackMenu,
    GeneMenu,
    QTLLegend,
    QTLMenu
  }
})
export default class SideBar extends Vue {
  get sideBar () {
    return this.$store.state.pantoStore.sideBar
  }

  set sideBar (state: SideBarState) {
    this.$store.commit('pantoStore/setSideBar', state)
  }

  get overlayHovered () {
    return this.$store.state.pantoStore.overlayHovered
  }

  set overlayHovered (state: boolean) {
    this.$store.commit('pantoStore/setOverlayHovered', state)
  }

  close () {
    this.sideBar = { enabled: false, wide: false, target: null }
    this.overlayHovered = false
  }
}
</script>

<style lang="scss">
#sidebar {
  position: fixed;
  top: 137px;
  right: 0;
  width: 500px;
  height: calc(100vh - 207px);
  background: #ededed;
  transform: translateX(100%);
  transition: transform 300ms ease-in-out;
  z-index: 1;
  overflow-y: scroll;
  border-left: 1px solid #cecece;
  text-align: left;

  .btn-close {
    float: right;
    margin: 12px 12px 0 0;
  }

  i.title-icon {
    margin-right: 8px !important;
  }

  .dot {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 8px;

    &.violet {
      background: #ABBAE2;
    }
    &.orange {
      background: #F2B963;
    }
    &.green {
      background: #BCE2AB;
    }
  }

  .header {
    margin-bottom: 10px;
    padding: 16px;

    .header-title {
      font-size: 18px;
      font-weight: 700;
    }

    .header-icon {
      font-size: 30px !important;
      margin-right: 10px;
    }
  }

  ul {
    margin-bottom: 20px;
    padding-left: 0;
  }

  li {
    margin: -6px 0;
  }

  .panel-title{
    font-weight: 700;
    font-size: 16px;
  }

  .panel-content-title {
    font-size: 14px;
    font-weight: 700;
  }

  .panel-content {
    font-size: 14px;
    font-weight: 400;
  }

  .content {
    padding: 2px 12px;
    margin-bottom: 12px;

    .inner {
      margin-top: 12px;
    }
  }

  .divider {
    margin: 14px 0;
  }

  &.visible{
    transform: translateX(0);
  }

  &.wide {
    width: 80%;
  }
}
</style>

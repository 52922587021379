import Vue from 'vue'
import store from '@/store'
import { ChunkStoreState, RootState } from '@/types/StoreTypes'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { ReadTrack, QTL } from '@/types/Types'
import Config from '@/graph/Config'

const namespaced = true

const state: ChunkStoreState = {
  datasetList: [],
  datasetFolder: '',
  dataset: null,
  binWidth: null,
  lastBinWidth: null,
  cachedChunks: {},
  cachedBins: 0,
  nrChunksToLoad: 0,
  zoomLevels: [],
  defaultBin: 1,
  // Current state
  currentMaxBin: 1,
  currentLastColumn: 0,
  currentFirstColumn: null,
  currentFirstBin: null,
  currentLastBin: 0,
  currentZoomLevel: null,
  currentChunk: 0,
  // Tracks
  trackMap: new Map(),
  graphTracks: new Map(),
  rawGraphTracks: new Map(),
  vcfTracks: new Map(),
  rawVCFTracks: [],
  vcfGroupNames: [],
  readTracks: {},
  qtlsInCachedChunks: new Map<string, QTL>(),
  allQTLsInCachedChunks: new Map<string, QTL>(),
  qtlCellHeight: 5,
  nbTracks: ''
}

const mutations: MutationTree<ChunkStoreState> = {
  // -------------------------------
  // graph Tracks
  // -------------------------------

  setGraphTracks (state, payload) {
    state.graphTracks = payload
  },

  addGraphTrack (state, payload) {
    const covdBins = state.graphTracks.get(payload?.id)
    if (covdBins) {
      state.graphTracks.set(payload.id, covdBins + payload.val)
    } else {
      state.graphTracks.set(payload.id, payload.val)
    }
  },

  deleteGraphTrack (state, payload) {
    const covdBins = state.graphTracks.get(payload?.id)
    if (covdBins) {
      state.graphTracks.set(
        payload.id, covdBins - payload.val
      )
      if (covdBins && covdBins <= 0) {
        state.graphTracks.delete(payload.id)
      }
    }
  },

  setRawGraphTracks (state, payload) {
    state.rawGraphTracks = payload
  },

  addRawGraphTrack (state, payload) {
    const covdBins = state.rawGraphTracks.get(payload?.id)
    if (covdBins) {
      state.rawGraphTracks.set(payload.id, covdBins + payload.val)
    } else {
      state.rawGraphTracks.set(payload.id, payload.val)
    }
  },

  deleteRawGraphTrack (state, payload) {
    const covdBins = state.rawGraphTracks.get(payload?.id)
    if (covdBins) {
      state.rawGraphTracks.set(
        payload.id, covdBins - payload.val
      )
      if (covdBins && covdBins <= 0) {
        state.rawGraphTracks.delete(payload.id)
      }
    }
  },

  // -------------------------------
  // vcf Tracks
  // -------------------------------

  setVCFTracks (state, payload) {
    state.vcfTracks = payload
  },

  storeVCFTrack (state, payload) {
    Vue.set(state.cachedChunks[payload.chunkID].vcfTracks, payload.trackName, payload.data)
  },

  addRawVCFTrack (state, payload) {
    state.rawVCFTracks.push(payload)
  },

  setVCFGroupNames (state, payload) {
    state.vcfGroupNames = payload
  },

  // -------------------------------
  // QTL Tracks
  // -------------------------------
  setQTLsInCachedChunks (state, payload) {
    state.qtlsInCachedChunks = payload
  },

  setAllQTLsInCachedChunks (state, payload) {
    state.allQTLsInCachedChunks = payload
  },

  // -------------------------------
  // read Tracks
  // -------------------------------

  setReadTracks (state, payload) {
    state.readTracks = payload
  },

  addReadTracks (state, payload) {
    Vue.set(state.readTracks, payload.id, payload.data)
  },

  // -------------------------------

  setDataset (state, payload) {
    state.dataset = payload
    state.datasetFolder = Config.datasetFolderName + payload + '/'
  },

  setDefaultBin (state, payload) {
    state.defaultBin = payload
  },

  setTrackMap (state, payload) {
    state.trackMap = payload
  },

  setNbTracks (state, payload) {
    state.nbTracks = payload
  },

  setDatasetList (state, payload) {
    state.datasetList = payload
  },

  setBinWidth (state, payload) {
    state.binWidth = payload
  },

  setLastBinWidth (state, payload) {
    state.lastBinWidth = payload
  },

  setCurrentMaxBin (state, payload) {
    state.currentMaxBin = payload
  },

  setCachedChunks (state, payload) {
    state.cachedChunks = payload
  },

  addCachedChunks (state, payload) {
    Vue.set(state.cachedChunks, payload.id, payload.data)
  },

  deleteCachedChunks (state, payload) {
    Vue.delete(state.cachedChunks, payload)
  },

  setCachedBins (state, payload) {
    state.cachedBins = payload
  },

  addCachedBins (state, payload) {
    state.cachedBins = state.cachedBins + payload
  },

  decrCachedBins (state, payload) {
    state.cachedBins = state.cachedBins - payload
  },

  setCurrentFirstBin (state, payload) {
    state.currentFirstBin = payload
  },

  setCurrentLastColumn (state, payload) {
    state.currentLastColumn = payload
  },

  setCurrentFirstColumn (state, payload) {
    state.currentFirstColumn = payload
  },

  setCurrentLastBin (state, payload) {
    state.currentLastBin = payload
  },

  setCurrentZoomLevel (state, payload) {
    state.currentZoomLevel = payload
  },

  setNrChunksToLoad (state, payload) {
    state.nrChunksToLoad = payload
  },

  setCurrentChunk (state, payload) {
    state.currentChunk = payload
  },

  setZoomLevels (state, payload) {
    state.zoomLevels = payload
  }
}

const actions: ActionTree<ChunkStoreState, RootState> = {}

const getters: GetterTree<ChunkStoreState, RootState> = {
  binWidth (state) {
    return state.binWidth
  },

  getVisibleTracks () {
    // List of graphTracks that are not disabled by the user
    const disabledGraphTracks = store.state.pantoStore.disabledGraphTracks
    const graphTracks = [...store.state.chunkStore.graphTracks.keys()].filter((graphTrack) => !disabledGraphTracks.includes(graphTrack))

    // List of readTracks that are not disabled by the user
    const disabledReadTracks = store.state.pantoStore.disabledReadTracks
    const chunk = store.state.chunkStore.currentChunk // TODO: collect readTracks from all cached chunks!
    let readTracks: Array<ReadTrack> = []
    if (store.state.metaStore.readsInFiles) {
      readTracks = store.state.chunkStore.readTracks[chunk].filter((readTrack: ReadTrack) => !disabledReadTracks.includes(readTrack.readset_name))
    }

    const enabledVCFTracks = store.state.pantoStore.enabledVCFTracks
    const vcfTracks = [...store.state.chunkStore.vcfTracks.keys()].filter((vcfTrack) => enabledVCFTracks.includes(vcfTrack))

    return {
      graphTracks,
      readTracks,
      vcfTracks
    }
  },

  getAllVisibleTracks () {
    const tracks = store.getters['chunkStore/getVisibleTracks']
    return tracks.graphTracks.concat(
      tracks.readTracks,
      tracks.vcfTracks
    )
  },

  getTrackNums () { // TODO readTracks
    const graph = [...store.state.chunkStore.graphTracks.keys()].length - store.state.pantoStore.disabledGraphTracks.length
    const read = 0
    // const numReadTracks = [...store.state.chunkStore.readTracks.keys()].length - store.state.pantoStore.disabledReadTracks.length
    const vcf = [...store.state.chunkStore.vcfTracks.keys()].length - store.state.pantoStore.disabledVCFTracks.length
    return {
      graph,
      read,
      vcf
    }
  }
}

export const ChunkStore: Module<ChunkStoreState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}

<template>
  <div>
    <input type="file" class="upload-input" accept=".csv" @change="handleMetaUpload" />
  </div>
</template>

<script lang="ts">
import { ApiQueryService } from '@/services/ApiQueryService'
import { Component, Vue } from 'vue-property-decorator'
import Config from '@/graph/Config'

@Component
export default class MetaUploader extends Vue {
  uploadedMetadataName = 'None'

  mounted () {
    const metadataName = localStorage.getItem('metadataName')
    if (metadataName) {
      this.uploadedMetadataName = metadataName
    }
  }

  handleMetaUpload (event: Event) {
    const file = (event.target as HTMLInputElement).files?.[0]
    if (file) {
      const path = Config.datasetFolderName
      ApiQueryService.uploadFile(path, file, Config.metadataFileName)
        .then(() => {
          this.$store.commit('metaStore/setUploadedMetadata', file)
        })
    }
  }
}
</script>

<style scoped>
.meta-name {
  margin-top: 20px;
}

.upload-input {
  margin-top: 20px;
}
</style>

import Papa from 'papaparse'
import { ApiQueryService } from '@/services/ApiQueryService'
import PixiConfig from '@/graph/Config'

export default class PlotService {
  public static getPlotData (): Promise<any> {
    return new Promise<any>((resolve) => {
      ApiQueryService.getFile(PixiConfig.datasetFolderName + 'plot.csv').then((response: any) => {
        const rawPlot = Papa.parse(response, { header: true, skipEmptyLines: true })
        const plot = rawPlot.data
        if (plot.length === 0) {
          console.warn('Empty plot data')
        } else {
          resolve(plot)
        }
      }).catch(() => {
        console.warn('No plot.csv file found in the chunk folder')
      })
    })
  }
}

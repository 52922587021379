<template>
  <div
    class="window-container noselect"
    ref="windowContainer"
    :style="{ width: width, height: height, zIndex: zIndex }"
    :class="{ visible: enabled }"
    @mouseover="overlayHovered = true"
    @mouseleave="overlayHovered = false"
    @mousedown="focus"
  >
    <!-- Button close -->
    <button @click="close" class="btn-close" ref="closeBtn"><v-icon>mdi-close</v-icon></button>
    <!-- Drag handle -->
    <div
      ref="dragHandle"
      class="dragHandle"
      @mousedown="dragStart"
      @mouseup="dragEnd"
    ><v-icon class="icon">mdi-drag</v-icon></div>
    <!-- Resize handle -->
    <div
      ref="resizeHandle"
      class="resizeHandle"
      @mousedown="resizeStart"
    ><v-icon class="icon">mdi-arrow-expand</v-icon></div>
    <!-- Slot -->
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch, Provide } from 'vue-property-decorator'

@Component
export default class Window extends Vue {
  @Prop({ default: false }) enabled!: boolean
  @Prop({ required: true }) id!: string

  @Provide() width = '980px'
  @Provide() height = '640px'

  container: HTMLElement | null = null
  dragging = false
  resizing = false
  dragHandleOffsetX = 0.0
  dragHandleOffsetY = 0.0
  resizeHandleOffsetX = 0.0
  resizeHandleOffsetY = 0.0
  zIndex = 10

  // ------------------------------------------------

  get overlayHovered () {
    return this.$store.state.pantoStore.overlayHovered
  }

  set overlayHovered (state: boolean) {
    this.$store.commit('pantoStore/setOverlayHovered', state)
  }

  get focusedWindow () {
    return this.$store.state.pantoStore.focusedWindow
  }

  set focusedWindow (window: string) {
    this.$store.commit('pantoStore/setFocusedWindow', window)
  }

  // ------------------------------------------------

  @Watch('enabled')
  onEnabledChange (newVal: boolean) {
    if (newVal) {
      this.focus()
    } else {
      this.blur()
    }
  }

  @Watch('focusedWindow')
  onFocusedWindowChange (newVal: string) {
    if (newVal !== this.id) {
      this.blur()
    }
  }

  // ------------------------------------------------

  mounted () {
    this.container = this.$refs.windowContainer as HTMLElement
  }

  close () {
    this.overlayHovered = false
    this.$emit('close')
  }

  focus () {
    this.focusedWindow = this.id
    this.zIndex = 1000
  }

  blur () {
    this.zIndex = 10
  }

  // ------------------------------------------------
  // Dragging

  dragStart (e: MouseEvent) {
    if (e.target === this.$refs.dragHandle) {
      window.addEventListener('mousemove', this.doDrag)
      this.dragging = true
      this.dragHandleOffsetX = e.offsetX
      this.dragHandleOffsetY = e.offsetY
    }
  }

  dragEnd (e: MouseEvent) {
    if (e.target === this.$refs.dragHandle) {
      window.removeEventListener('mousemove', this.doDrag)
      this.dragging = false
    }
  }

  doDrag (e: MouseEvent) {
    if (this.container) {
      this.container.style.left = (e.clientX - this.dragHandleOffsetX) + 'px'
      this.container.style.top = (e.clientY - this.dragHandleOffsetY) + 'px'
    }
  }

  // ------------------------------------------------
  // Resizing

  resizeStart (e: MouseEvent) {
    if (e.currentTarget === this.$refs.resizeHandle) {
      window.addEventListener('mousemove', this.doResize)
      window.addEventListener('mouseup', this.resizeEnd)
      this.resizing = true
      this.resizeHandleOffsetX = e.offsetX
      this.resizeHandleOffsetY = e.offsetY
    }
  }

  resizeEnd () {
    window.removeEventListener('mousemove', this.doResize)
    this.resizing = false
  }

  doResize (e: MouseEvent) {
    if (this.resizing && this.container) {
      const newWidth = e.clientX - this.container.getBoundingClientRect().left
      const newHeight = e.clientY - this.container.getBoundingClientRect().top

      // Set minimum size
      const minWidth = 734
      const minHeight = 400

      // Set new size for the window container
      this.width = Math.max(newWidth, minWidth) + this.resizeHandleOffsetX + 'px'
      this.height = Math.max(newHeight, minHeight) + this.resizeHandleOffsetY + 'px'

      const rawWidth = this.width.replace('px', '')
      const rawHeight = this.height.replace('px', '')

      // Emit resize event
      this.$emit('resize', { width: rawWidth, height: rawHeight })
    }
  }
}

</script>

<style lang="scss" scoped>
$border-radius: 10px;

.window-container {
  position: fixed;
  padding-top: 30px;
  z-index: 10;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: none;
  border-radius: $border-radius;
  width: 980px;
  height: 640px;
  top: calc(50% - 320px);
  left: calc(50% - 490px);

  &.visible {
    display: block;
  }
}

.btn-close {
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;
  background-color: rgba(221, 70, 70, 0.5);
  border-radius: $border-radius 0 0 0;
  z-index: 101;
}

.dragHandle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 30px;
  background-color: rgba(162, 162, 162, 0.5);
  cursor: grab;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  z-index: 100;
  border-radius: $border-radius $border-radius 0 0;

  .icon {
    pointer-events: none;
  }
}

.resizeHandle {
  position: absolute;
  bottom: 10px;
  right: 10px;
  cursor: nwse-resize;
  display: flex;
  z-index: 101;

  .icon {
    transform: rotate(-90deg) !important;
  }
}
</style>

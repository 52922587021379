<template>
    <div>
      <div class="header">
        <div class="title">Selected QTLs</div>
      </div>
      <div class="content">
        <ul>
          <li v-for="(qtl, index) in currentQTLs" :key="index">
            <v-switch color="grey" inset v-model="qtl[1].enabled" class="switch"></v-switch>
            <v-btn
              elevation="0"
              rounded
              class="btn-trait"
              :class="{active: activeBtn === qtl[1].trait}"
              @mouseenter="showHighlight(qtl[1])"
              @mouseleave="hideHighlight"
              @click="toggleStickyHighlight($event, qtl[1])"
            >
            <div class="dot" :style="{ backgroundColor: qtl[1].color}"></div>
            <span class="label">{{ qtl[1].trait }}</span>
            <span class="label" v-if="qtl[1].nbTracks > 1">&nbsp;({{ qtl[1].nbTracks }})</span>
            </v-btn>
          </li>
        </ul>
      </div>
    </div>
  </template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import { QTL } from '@/types/Types'

@Component
export default class QTLLegend extends Vue {
  panel = [0]
  stickyHighlight = false
  activeBtn = ''
  lastTarget = null

  get enabledQTLTracks () {
    return this.$store.state.pantoStore.enabledQTLTracks
  }

  get currentQTLs () {
    const currentQTLs = this.getCurrentQTLs() as Map<number, QTL>
    const currentQTLsWithEnabled = new Map([...currentQTLs.entries()].map(([key, qtl]) => {
      return [key, { ...qtl, enabled: this.$store.state.pantoStore.enabledQTLTracks.includes(qtl.trait) }]
    }))
    return currentQTLsWithEnabled
  }

  get qtlCellHeight () {
    return this.$store.state.chunkStore.qtlCellHeight
  }

  getCurrentQTLs () {
    // Filter qtls to display only one per trait
    // const qtlsInCachedChunks = this.$store.state.chunkStore.allQTLsInCachedChunks // all qtls
    const qtlsInCachedChunks = this.$store.state.chunkStore.qtlsInCachedChunks // visible qtls only
    const currentTraits = new Map<number, QTL>()
    qtlsInCachedChunks.forEach((value: any, key: any) => {
      if (!currentTraits.has(value.trait)) {
        currentTraits.set(value.trait, value)
        const qtl = currentTraits.get(value.trait) as any
        if (qtl) {
          qtl.height = this.$store.state.chunkStore.qtlCellHeight
          qtl.nbTracks = 1
        }
      } else {
        const qtl = currentTraits.get(value.trait) as any
        if (qtl) {
          qtl.height += this.$store.state.chunkStore.qtlCellHeight
          qtl.nbTracks++
        }
      }
    })
    return currentTraits
  }

  showHighlight (qtl: QTL) {
    if (!this.stickyHighlight) {
      this.$store.commit('graphStore/qtlHighlight', { enabled: true, y: qtl.y, height: qtl.height })
    }
  }

  hideHighlight () {
    if (!this.stickyHighlight) {
      this.$store.commit('graphStore/qtlHighlight', { enabled: false, y: 0, height: this.qtlCellHeight })
    }
  }

  toggleStickyHighlight (e: MouseEvent, qtl: QTL) {
    if (this.lastTarget !== e.currentTarget) {
      this.stickyHighlight = true
      this.activeBtn = qtl.trait
      this.$store.commit('graphStore/qtlHighlight', { enabled: true, y: qtl.y, height: qtl.height })
    } else {
      this.stickyHighlight = !this.stickyHighlight
      this.activeBtn = this.stickyHighlight ? qtl.trait : ''
    }
    this.lastTarget = e.currentTarget as null
  }
}
</script>

<style lang="scss" scoped>
  .dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 14px;
  }

  ul {
    margin: 0 12px;
  }

  .label {
    font-size: 11px;
  }

  .switch {
    display: inline-block;
    transform: translateY(5px);
    display: none;
  }

  .v-input--selection-controls {
    margin-top: 0 !important;
  }

  .btn-trait {
    background-color: #f7f7f7 !important;
    border: 1px solid #cecece !important;

    &.active {
      background-color: #ffffff !important;
      border: 1px solid #3b34ff !important;
    }
  }
</style>

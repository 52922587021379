import { ApiQueryService } from './ApiQueryService'
import PixiConfig from '@/graph/Config'

export interface GeneFunction {
  database: string,
  database_record: string,
  start_position: number,
  end_position: number,
  score_float: number,
  score_float_name: string,
  match_description: string,
  term_type: string,
  term_value: string,
  term_description: string
}

export interface HomologyGroup {
  group: string,
  type: string,
  description: string
}

export interface GenericGenePassport {
  summary: {
    assembly: string,
    position: string,
    length: string,
    functionalDescription: string
  },
  functions: GeneFunction[]
  homology: HomologyGroup[]
}

export interface GenePassport {
  name: string,
  genome_name: string,
  dataset_name: string,
  start: number,
  stop: number,
  strand: string,
  // geneSeq: string | null,
  // protSeq: string | null,
  // cds: string | null,
  descr: string,
  // GO: string[],
  anno: Record<string, string[]>,
  ortho_group: string
}

export class GeneService {
  public static async getGenericGenePassport (geneName: string): Promise<GenericGenePassport> {
    return new Promise<GenericGenePassport>((resolve, reject) => {
      ApiQueryService.getFile(PixiConfig.datasetFolderName + 'annoscore.json').then((response: any) => {
        const genePassport: GenericGenePassport = {
          summary: {
            assembly: response.summary.assembly,
            position: response.summary.position,
            length: response.summary.length,
            functionalDescription: response.summary.functionalDescription
          },
          functions: response.functions,
          homology: response.homology
        }
        resolve(genePassport)
      }).catch((error: any) => {
        reject(error)
      })
    })
  }

  public static async getGenePassport (geneName: string): Promise<GenePassport> {
    return new Promise<GenePassport>((resolve) => {
      ApiQueryService.getGeneInfos('genePassport', geneName)
      // ApiQueryService.getFile('genePass1.json')
        .then((response) => {
          const genePassport: GenePassport = {
            name: response.gene_id,
            genome_name: response.genome_name,
            dataset_name: response.dataset_name,
            start: response.start,
            stop: response.stop,
            strand: response.strand,
            // geneSeq: response.gene_seq ? response.gene_seq : '',
            // protSeq: response.protSeq ? response.protSeq : '',
            // cds: response.cds ? response.cds : '',
            descr: response.descr === 'None' ? '' : response.descr,
            // GO: response.go === 'None' ? [] : response.go,
            anno: response.anno === 'None' ? {} : response.anno,
            ortho_group: response.ortho_group === null ? '' : response.ortho_group
          }
          resolve(genePassport)
        })
        .catch(() => {
          // reject(error)
        })
    })
  }

  public static async getGeneSeq (geneName: string): Promise<string> {
    return new Promise<string>((resolve) => {
      ApiQueryService.getGeneInfos('geneSeq', geneName)
        .then((response) => {
          resolve(response)
        })
        .catch(() => {
          // reject(error)
        })
    })
  }

  public static async getProtSeq (geneName: string): Promise<string> {
    return new Promise<string>((resolve) => {
      ApiQueryService.getGeneInfos('proteinSeq', geneName)
        .then((response) => {
          resolve(response.seq)
        })
        .catch(() => {
          // reject(error)
        })
    })
  }

  public static async getCDSSeq (geneName: string): Promise<string> {
    return new Promise<string>((resolve) => {
      ApiQueryService.getGeneInfos('cdsSeq', geneName)
        .then((response) => {
          resolve(response.seq)
        })
        .catch(() => {
          // reject(error)
        })
    })
  }
}

<template>
  <div>
    <div class="header">
      <div class="title">{{$t('meta-menu.title')}}</div>
    </div>
    <div class="content">
      <v-expansion-panels v-model="panel" multiple focusable>
        <!-- Categories -->
        <v-expansion-panel>
          <v-expansion-panel-header><div class="panel-title">{{$t('meta-menu.categories-title')}}</div></v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-checkbox
              v-model="allMetaCategoriesEnabled"
              :label="'All'"
              @change="toggleAllCategories"
              hide-details>
            </v-checkbox>
            <v-divider class="divider"></v-divider>
            <ul>
              <li v-for="(category, index) in metaDataCategories" :key="index">
                <v-checkbox
                  :input-value="isMetaCategoryEnabled(category)"
                  :label="category"
                  @change="toggleCategory(category)"
                  hide-details>
                </v-checkbox>
              </li>
            </ul>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- MetaUploader -->
        <v-expansion-panel>
          <v-expansion-panel-header><v-icon class="title-icon" small>mdi-upload</v-icon><div class="panel-title">{{$t('meta-menu.upload-title')}}</div></v-expansion-panel-header>
          <v-expansion-panel-content>
            <MetaUploader />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import MetaUploader from './MetaUploader.vue'

@Component({
  components: {
    MetaUploader
  }
})
export default class MetaMenu extends Vue {
  // --- Data ---

  panel = [0, 1]

  // --- Getters / Setters ---

  get allMetaCategoriesEnabled () {
    return this.$store.state.pantoStore.allMetaCategoriesEnabled
  }

  set allMetaCategoriesEnabled (value: boolean) {
    this.$store.commit('pantoStore/setAllMetaCategoriesEnabled', value)
  }

  get enabledMetaCategories () {
    return this.$store.state.pantoStore.enabledMetaCategories
  }

  set enabledMetaCategories (categories: string[]) {
    this.$store.commit('pantoStore/setEnabledMetaCategories', categories)
  }

  // Getter only
  get metaDataCategories () {
    return this.$store.state.metaStore.metaDataCategories.filter((category: string) => category !== 'Name')
  }

  // Getter only
  get isMetaCategoryEnabled () {
    return this.$store.getters['pantoStore/isMetaCategoryEnabled']
  }

  // --- Methods ---

  toggleAllCategories () {
    this.$store.dispatch('pantoStore/toggleAllCategories')
  }

  toggleCategory (category: string) {
    this.$store.dispatch('pantoStore/toggleCategory', category)
    console.log(this.$store.getters['metaStore/metaDataCategories'])
  }
}
</script>

<template>
  <div>
    <h1>Login</h1>
    <v-form v-model="valid" lazy-validation>
    <div class="form-container">
      <v-text-field
        v-model="user"
        label="User name"
        prepend-inner-icon="mdi-account"
        required
      ></v-text-field>

      <!-- <v-text-field
        v-model="secretAccessKey"
        label="Secret access key"
        required
      ></v-text-field> -->

      <v-text-field
        v-model="pwd"
        :append-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
        :type="visible ? 'text' : 'password'"
        placeholder="Enter your password"
        label="Enter your password"
        prepend-inner-icon="mdi-lock-outline"
        variant="outlined"
        @click:append="visible = !visible"
      ></v-text-field>

      <v-btn
        :disabled="!valid"
        color="success"
        class="mr-4"
        @click="validate"
      >Login</v-btn>
      </div>
    </v-form>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Login extends Vue {
  user = ''
  pwd = ''
  valid = false
  visible = false

  validate () {
    if (this.user && this.pwd) {
      this.$store.dispatch('authStore/authRequest', {
        name: this.user,
        password: this.pwd
      }).then(() => {
        this.$router.push({ name: 'Genome' })
      }).catch((error) => {
        console.error(error)
        alert('Wrong credentials')
      })
    }
  }
}
</script>

<style>
  .form-container {
    width: 400px;
    margin: 0 auto;
  }
</style>

import store from '@/store'
import { QTL } from '@/types/Types'
import { ApiQueryService } from '@/services/ApiQueryService'
import GeneralUtils from '@/utils/GeneralUtils'
import PixiConfig from '@/graph/Config'
import ColorUtils from '@/utils/ColorUtils'

export default class QTLService {
  // NOTE: we use static methods here because this service is stateless
  // Futures services should be implemented the same way
  // (TODO: add this to documentation as a guideline for future services)

  // ----------------------
  // Public methods
  // ----------------------

  public static getQTLData (): Promise<Map<string, QTL>> {
    return new Promise<Map<string, QTL>>((resolve) => {
      ApiQueryService.getFile(store.state.chunkStore.datasetFolder + PixiConfig.qtlFileName).then((response) => {
        const keys = Object.keys(response)
        // Get unique traits
        const uniqueQTLs = [...new Set(Object.values(response).map((item: any) => item.trait))]
        // Get color scale
        const colorScale = ColorUtils.getColorScale(uniqueQTLs.length, true)

        // Create a map to store the QTL metadata
        const qtls = new Map()

        // Add qtls to qtls
        const nbKeys = keys.length
        for (let i = 0; i < nbKeys; i++) {
          const obj = response[keys[i]]
          if (!('trait' in obj)) {
            console.log('WARNING: no "trait" for QTL num:', i)
            continue
          }
          const qtl: QTL = {
            trait: obj.trait,
            color: store.state.metaStore.genomeColorScale ? store.state.metaStore.genomeColorScale.get(obj.trait) : colorScale[uniqueQTLs.indexOf(obj.trait)],
            y: 0,
            height: 0,
            nbTracks: 0,
            refPath: obj.ref_path,
            chromosome: obj.Chrom
          }
          if ('Object Type' in obj) { qtl.objectType = obj['Object Type'] }
          if ('start' in obj) { qtl.startPos = GeneralUtils.numberWithCommas(Number(obj.start)) }
          if ('end' in obj) { qtl.endPos = GeneralUtils.numberWithCommas(Number(obj.end)) }
          if ('Start_cM' in obj) { qtl.start_cM = obj.Start_cM }
          if ('Stop_cM' in obj) { qtl.end_cM = obj.Stop_cM }
          if ('LG' in obj) { qtl.LG = obj.LG }
          if ('ID' in obj) { qtl.ID = obj.ID }
          if ('flipped_bounds' in obj) { qtl.flippedBounds = obj.flipped_bounds }
          qtls.set(keys[i], qtl)
        }
        resolve(qtls)
      }).catch((error) => {
        console.warn('Error loading QTL data: ', error)
      })
    })
  }

  public static getQTLIDsOnPaths (chunkID: number): Promise<Array<Array<number>>> {
    store.commit('graphStore/areQTLsLoading', true)
    return new Promise<Array<Array<number>>>((resolve, reject) => {
      const file = store.state.chunkStore.datasetFolder + 'bin' + store.state.chunkStore.binWidth + '/qtls/qtls.' + chunkID + '.json'
      ApiQueryService.getFile(file).then((response) => {
        if (response.qtl_on_paths[0]) {
          const rawQTLs = response.qtl_on_paths[0].qtl_tracks
          store.commit('graphStore/areQTLsLoading', false)
          resolve(rawQTLs)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  public static getQTLsInCachedChunks (): Promise<Map<number, QTL>> {
    return new Promise<Map<number, QTL>>((resolve, reject) => {
      const qtls = new Map<number, QTL>()
      const allQtls = new Map<number, QTL>()
      console.log('enabledQTLs', store.state.pantoStore.enabledQTLTracks)
      QTLService.joinQTLIDsInCachedChunks()
        .then((qtlIDs) => {
          console.log('joinQTLIDsInCachedChunks:', qtlIDs)
          if (qtlIDs.length > 0) {
            for (let i = 0; i < qtlIDs.length; i++) {
              const qtl = store.state.metaStore.qtlMetadata.get(qtlIDs[i].toString())
              if (qtl) {
                allQtls.set(qtlIDs[i], qtl)
                // We filter by user enabled QTLs
                if (store.state.pantoStore.enabledQTLTracks.includes(qtl.trait)) {
                  qtls.set(qtlIDs[i], qtl)
                  console.log('QTL ID:', qtlIDs[i], 'added to qtls')
                }
              }
            }
            if (qtls.size > 0) {
              // Prepare qtls to be sorted by trait (on next iteration)
              qtls[Symbol.iterator] = function * () {
                yield * [...this.entries()].sort((a, b) => a[1].trait.localeCompare(b[1].trait))
              }
              // Iterate over qtls and set qtl.y
              let index = 0
              for (const qtl of qtls) {
                qtl[1].y = index * store.state.chunkStore.qtlCellHeight
                index++
              }
              store.commit('chunkStore/setAllQTLsInCachedChunks', allQtls)
              store.commit('chunkStore/setQTLsInCachedChunks', qtls)
              resolve(qtls)
            } else {
              console.log('No enabled QTLs found in cached chunks')
            }
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  // ----------------------
  // Private methods
  // ----------------------

  private static getQTLIDsInChunk (chunkID: number): Promise<Array<number>> {
    return new Promise<Array<number>>((resolve, reject) => {
      const file = store.state.chunkStore.datasetFolder + 'bin' + store.state.chunkStore.binWidth + '/qtls/qtls.' + chunkID + '.json'
      ApiQueryService.getFile(file).then((response) => {
        resolve(response.qtl_in_chunk)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  private static async joinQTLIDsInCachedChunks (): Promise<Array<number>> {
    const qtlidsicc = new Array<number>()
    return Promise.all(
      Object.keys(store.state.chunkStore.cachedChunks).map(async (chunkID) => {
        return QTLService.getQTLIDsInChunk(Number(chunkID)).then((qtlidsic) => {
          console.log('getQTLIDsInChunk:', qtlidsic)
          for (let i = 0; i < qtlidsic.length; i++) {
            // Push each unique id
            if (!qtlidsicc.includes(qtlidsic[i])) {
              qtlidsicc.push(qtlidsic[i])
            }
          }
        })
      })
    )
      .then(() => qtlidsicc)
      .catch((error) => {
        throw new Error(error)
      })
  }
}

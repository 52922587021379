import Vue from 'vue'
import Login from '@/views/Login.vue'
import Router from 'vue-router'

// Views
import Graph from '@/views/Graph.vue'
import Genome from './views/Genome.vue'
import store from './store'
import Expression from './views/Expression.vue'
import Pipelines from './views/Pipelines.vue'

Vue.use(Router)

// We retreive the query (when pasted into the address bar) to directly load the shared view
declare module 'vue/types/vue' {
  interface Vue {
    $query: Record<string, string>
  }
}

Vue.mixin({
  beforeCreate () {
    const query = Object.fromEntries(new URLSearchParams(window.location.search))
    this.$query = Vue.observable(query)
  }
})

// Router init
const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/genome'
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/genome',
      name: 'Genome',
      component: Genome
    },
    {
      path: '/graph',
      name: 'Graph',
      component: Graph
    },
    {
      path: '/expression/:geneID',
      name: 'Expression',
      component: Expression,
      props: true
    },
    {
      path: '/pipelines/',
      name: 'Pipelines',
      component: Pipelines,
      props: true
    }
  ]
})

router.beforeEach((to, from, next) => {
  const publicPages: Array<string> = ['Login']

  const isAuthenticated = store.getters['authStore/isAuthenticated']
  console.log('authorized?', isAuthenticated)

  if (!isAuthenticated && !publicPages.includes(to.name || '')) {
    // Redirect to Login only if the user is not authenticated and not already heading to Login
    next({ name: 'Login' })
    // } else if (isAuthenticated && to.name === 'Login') {
    //   // Redirect authenticated users away from Login to Dashboard
    //   next({ name: 'Genome' })
  } else {
    // Proceed to the intended route
    next()
  }
})

export default router

import { render, staticRenderFns } from "./GeneTree.vue?vue&type=template&id=1f20acc5&scoped=true"
import script from "./GeneTree.vue?vue&type=script&lang=ts"
export * from "./GeneTree.vue?vue&type=script&lang=ts"
import style0 from "./GeneTree.vue?vue&type=style&index=0&id=1f20acc5&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f20acc5",
  null
  
)

export default component.exports
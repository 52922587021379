<template>
  <div class="slider-container">
    <div id="position">
      <span>Position: <strong>{{ numFormatter(sliderCoords[0]) }}</strong></span>
    </div>
    <div id="slider">
      <v-slider
        :disabled="!sliderEnabled"
        :max="maxSlider"
        color="#000000"
        track-color="#000000"
        @change="change"
        v-model="sliderPosition[0]">
      </v-slider>
    </div>
  </div>
</template>

<script lang="ts">
import GeneralUtils from '@/utils/GeneralUtils'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Slider extends Vue {
  get sliderEnabled () {
    return this.$store.state.pantoStore.sliderEnabled
  }

  set sliderEnabled (value) {
    this.$store.commit('pantoStore/setSliderEnabled', value)
  }

  get sliderPosition () {
    return this.$store.state.pantoStore.sliderPosition
  }

  get sliderCoords () {
    const left = this.sliderPosition[0] === 1 ? 1 : (this.sliderPosition[0] - 1) * this.$store.state.chunkStore.binWidth
    const right = this.sliderPosition[1] * this.$store.state.chunkStore.binWidth
    return [left, right]
  }

  get maxSlider () {
    return this.$store.state.chunkStore.currentMaxBin
  }

  // get hintText () {
  //   let start = (this.sliderPosition[0] - 1) * this.$store.state.chunkStore.binWidth
  //   if (start === 0) start = 1
  //   const end = this.sliderPosition[1] * this.$store.state.chunkStore.binWidth
  //   // return 'Position: ' + GeneralUtils.numberWithCommas(start) + ' - ' + GeneralUtils.numberWithCommas(end)
  //   return 'Position: ' + GeneralUtils.numberWithCommas(start)
  // }

  // get selectedBinWidth () {
  //   return this.$store.state.chunkStore.binWidth
  // }

  numFormatter (num: number) {
    return GeneralUtils.numberWithCommas(num)
  }

  change () {
    this.$emit('change', this.sliderPosition)
  }
}
</script>

<style lang="scss" scoped>
.slider-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
}

#slider {
  width: 50%;
  height: 100%;
}

#position {
  height: 100%;
  line-height: 34px;
  margin-right: 20px;
}
</style>

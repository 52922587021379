import { render, staticRenderFns } from "./Legend.vue?vue&type=template&id=0185571c&scoped=true"
import script from "./Legend.vue?vue&type=script&lang=ts"
export * from "./Legend.vue?vue&type=script&lang=ts"
import style0 from "./Legend.vue?vue&type=style&index=0&id=0185571c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0185571c",
  null
  
)

export default component.exports
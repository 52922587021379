<template>
    <!-- We add 12px to 'y', which is the padding of the .container -->
    <div id="qtl-highlight"
      v-if="qtlHighlight.enabled"
      :style="{ top: qtlHighlight.y+12 + 'px', height: qtlHighlight.height + 'px' }"
    ></div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class QTLHighlight extends Vue {
  get qtlHighlight () {
    return this.$store.state.graphStore.qtlHighlight
  }
}
</script>

<style lang="scss">
#qtl-highlight {
  width: calc(100% - 24px);
  height: 5px;
  background-color: #0800ff6c;
  position: absolute;
  top: 0;
  left: 12px;
  right: 12px;
  border: 1px solid #1900ff;
  outline: 1px solid #ffffff;
}
</style>

import { GenomeStoreState, RootState } from '@/types/StoreTypes'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { Genome } from '@/types/Types'

const namespaced = true

const state: GenomeStoreState = {
  genome: null,
  selectedQTLs: [], // list of QTLs (any)
  selectedTraits: {}, // Record<string, string>: trait -> color
  highlightedTraits: [], // list of traits (string)
  filteredQTLIDs: [], // list of QTLIDs (string)
  lengthFilter: 10000000,
  areGenMarkedQTLsHidden: false,
  minQTLLength: 500000, // minimum height of 500k // readonly (no setter)
  maxQTLLength: 10000000 // maximum height of 55.6M // readonly (no setter)
}

const mutations: MutationTree<GenomeStoreState> = {
  setGenome (state, genome: Genome) {
    state.genome = genome
  },

  setSelectedQTLs (state, qtls) {
    state.selectedQTLs = qtls
  },

  setSelectedTraits (state, traits) {
    state.selectedTraits = traits
  },

  setHighlightedTraits (state, qtls) {
    state.highlightedTraits = qtls
  },

  setFilteredQTLIDs (state, qtlIDs) {
    state.filteredQTLIDs = qtlIDs
  },

  setLengthFilter (state, length) {
    state.lengthFilter = length
  },

  setMaxQTLLength (state, length) {
    state.maxQTLLength = length
  },

  setAreGenMarkedQTLsHidden (state, hidden) {
    state.areGenMarkedQTLsHidden = hidden
  }
}

const actions: ActionTree<GenomeStoreState, RootState> = {}
const getters: GetterTree<GenomeStoreState, RootState> = {}

export const GenomeStore: Module<GenomeStoreState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}

<template>
  <v-btn
    :class="{active: active}"
    class="qtl-chip"
    elevation="0"
    rounded
    @mouseover="$emit('mouseover')"
    @mouseout="$emit('mouseout')"
    @click="$emit('click')"
  >
    <div class="dot" :style="{ backgroundColor: color}"></div>
    <span class="trait">{{ formattedTrait }}</span>
  </v-btn>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class QTLChip extends Vue {
  @Prop({ default: 'QTLChip' }) trait!: string
  @Prop({ default: '#444' }) color!: string
  @Prop({ default: false }) active!: boolean

  maxChars = 30

  get formattedTrait () {
    return this.trait.length > this.maxChars ? this.trait.slice(0, this.maxChars) + '...' : this.trait
  }
}
</script>

<style lang="scss" scoped>
.qtl-chip {
  background-color: #f7f7f7 !important;
  border: 1px solid #cecece !important;
  margin: 0 $space-s $space-s 0 !important;
  transition: all 0.3s ease-in-out !important;

  &.active {
    background-color: #ffffff !important;
    border: 1px solid #3b34ff !important;
  }
}

.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: $space-s;
}

.trait {
  font-size: 10px;
}
</style>

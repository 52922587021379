<template>
  <div class="clusterplot">
    <!-- Toolbar -->
    <div class="toolbar">
      <v-select
        class="select"
        label="Metadata"
        :items="metaDataCategories"
        v-model="selectedMetaCategory"
        @change="plot"
      ></v-select>
    </div>
    <!-- Plot -->
    <div class="plot-container" ref="plotContainer"></div>
    <!-- No data message -->
    <div class="no-data" :class="{ visible: noDataToPlot }">
      <v-alert outlined>
        No data
      </v-alert>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Watch } from 'vue-property-decorator'
import Plotly from 'plotly.js-dist-min'

@Component
export default class ClusterPlot extends Vue {
  plotContainer: any
  data: any
  selectedMetaCategory = 'Cultivation'
  noDataToPlot = false
  isDataCategorical = false

  clusterPlots: Record<string, {categoryValue: number, trackName: string}[] | Record<string, number>> = {}

  layout = {
    title: '',
    barmode: 'stack',
    font: {
      // family: 'Courier New, monospace',
      size: 18
    },
    yaxis: {
      tickfont: {
        size: 14
      }
    }
  }

  get state () {
    return this.$store.state.pantoStore.clusterPlotState
  }

  get metaDataCategories () {
    return this.$store.state.metaStore.metaDataCategories.filter((category: string) => {
      const regexp = /Chr.*tree/
      return !category.match(regexp) && category !== 'Name'
    })
    // return this.$store.state.metaStore.metaDataCategories
  }

  @Watch('metaDataCategories', { immediate: true })
  onMetaDataCategoriesChange () {
    if (this.metaDataCategories.length > 0) {
      this.selectedMetaCategory = this.metaDataCategories[0]
    }
  }

  @Watch('state', { immediate: true, deep: true })
  onStateChange () {
    if (this.state.enabled) {
      this.plot()
    }
  }

  mounted () {
    this.plotContainer = this.$refs.plotContainer as HTMLElement
  }

  setClusterPlots (trackName: string, clusterName: string) {
    const categoricalValuesCount: Record<string, number> = {}

    // Skip tracks that don't have the selected metadata category
    if (this.$store.state.metaStore.metaData[trackName] === undefined) {
      return
    }

    // Get the value of the selected metadata category for the current track
    const categoryValue = this.$store.state.metaStore.metaData[trackName][this.selectedMetaCategory]
    if (categoryValue !== null && categoryValue !== undefined) {
      // If the category value is a string, count the occurrences of each value
      if (typeof categoryValue === 'string') {
        this.isDataCategorical = true
        if (Object.prototype.hasOwnProperty.call(categoricalValuesCount, categoryValue)) {
          categoricalValuesCount[categoryValue] = Number(categoricalValuesCount[categoryValue]) + 1
        } else {
          categoricalValuesCount[categoryValue] = 1
        }
      } else {
        this.isDataCategorical = false
      }

      // If the cluster doesn't exist yet, create it
      if (!Object.prototype.hasOwnProperty.call(this.clusterPlots, clusterName)) {
        if (typeof categoryValue === 'string') {
          this.clusterPlots[clusterName] = {} // Initialize as Record<string, number>
        } else {
          this.clusterPlots[clusterName] = [] // Initialize as number[]
        }
      }

      // Push the category value to the appropriate cluster
      if (typeof categoryValue === 'string') {
        (this.clusterPlots[clusterName] as Record<string, number>)[categoryValue] = ((this.clusterPlots[clusterName] as Record<string, number>)[categoryValue] || 0) + 1
      } else {
        (this.clusterPlots[clusterName] as {categoryValue: number, trackName: string}[]).push({ categoryValue, trackName })
      }
    }
  }

  plot () {
    // Reset clusterPlots
    this.clusterPlots = {}

    // Set clusterPlots
    for (const [clusterName, trackNames] of Object.entries(this.state.clusters)) {
      for (const trackName of trackNames as string[]) {
        this.setClusterPlots(trackName, clusterName)
      }
    }

    // Set the data to plot
    // If there is no data to plot, show a 'no data' message
    if (Object.keys(this.clusterPlots).length === 0) {
      this.noDataToPlot = true
    } else {
      this.noDataToPlot = false
      // If the data is categorical, plot a bar chart
      if (this.isDataCategorical) {
        this.data = Object.keys(this.clusterPlots).map(clusterName => {
          return {
            x: Object.keys(this.clusterPlots[clusterName]),
            y: Object.values(this.clusterPlots[clusterName]),
            name: clusterName,
            type: 'bar',
            marker: {
              color: this.state.plotColors[clusterName] // Set the color for each cluster
            }
          }
        })
      } else {
        // If the data is numerical, plot a box plot
        this.data = Object.keys(this.clusterPlots).map(clusterName => {
          return {
            y: Object.values(this.clusterPlots[clusterName]).map(cluster => cluster.categoryValue),
            text: Object.values(this.clusterPlots[clusterName]).map(cluster => `Track: ${cluster.trackName}`),
            name: clusterName,
            type: 'box',
            boxpoints: 'all',
            jitter: 0.3,
            pointpos: -1.5,
            marker: {
              color: this.state.plotColors[clusterName] // Set the color for each cluster
            }
          }
        })
      }
    }

    // Set the plot title
    this.layout.title = this.selectedMetaCategory

    // Plot
    Plotly.purge(this.plotContainer)
    Plotly.newPlot(this.plotContainer, this.data, this.layout)
  }

  resize (size: { width: number, height: number }) {
    Plotly.relayout(this.plotContainer, {
      width: size.width - 20,
      height: size.height - 40
    })
  }
}
</script>

<style lang="scss" scoped>
.clusterplot {
  width: 100%;
  height: 100%;
  position: relative;
}

.plot-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
}

.toolbar {
  margin: $space-l 0 0 $space-l;
  position: absolute;
  z-index: 1100;
}

.select {
  width: 200px;
}

.no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  border-radius: $border-radius;
  pointer-events: none;

  &.visible {
    opacity: 1;
  }
}
</style>

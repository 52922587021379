<template>
  <div>
    <div class="header">
      <div class="title">{{$t('qtl-menu.title')}}</div>
    </div>
    <div class="content">
      <!-- <div class="panel-content-title">Trait</div>
      <div class="dot" :style="{ backgroundColor: selectedQTL.color}"></div>
      <span class="panel-content">{{ selectedQTL.trait }}</span> -->
      <div style="height: 10px;"></div>
      <v-expansion-panels v-model="panel" multiple focusable>
        <v-expansion-panel>
          <v-expansion-panel-header><v-icon class="title-icon" small>mdi-bullseye</v-icon><div class="panel-title">QTL Information</div></v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="inner">
              <!-- Trait -->
              <div class="panel-content-title">Trait</div>
              <div class="dot" :style="{ backgroundColor: selectedQTL.color}"></div>
              <span class="panel-content">{{ selectedQTL.trait }}</span>
              <!-- Type / ID -->
              <!-- <v-divider v-if="'ID' in selectedQTL" class="divider"></v-divider> -->
              <div v-if="'ID' in selectedQTL" class="panel-content-title">ID <span class="panel-content">{{ selectedQTL.ID }}</span></div>
              <v-divider v-if="'ObjectType' in selectedQTL" class="divider"></v-divider>
              <div v-if="'ObjectType' in selectedQTL" class="panel-content-title">Type <span class="panel-content">{{ selectedQTL.ObjectType }}</span></div>
              <!-- Ref path -->
              <v-divider class="divider"></v-divider>
              <div class="panel-content-title">Ref path <span class="panel-content">{{ selectedQTL.ref_path }}</span></div>
              <!-- Genomic Coords -->
              <v-divider class="divider"></v-divider>
              <div class="panel-content-title">Genomic Coordinates <span class="panel-content"></span></div>
              <div class="panel-content-title">Chromosome <span class="panel-content">{{ selectedQTL.Chrom }}</span></div>
                <div v-if="'start' in selectedQTL" class="panel-content-title">Start pos <span class="panel-content">{{ numberWithCommas(selectedQTL.start) }}</span></div>
                <div v-if="'end' in selectedQTL" class="panel-content-title">End pos <span class="panel-content">{{ numberWithCommas(selectedQTL.end) }}</span></div>
              <!-- Genetic Coords -->
              <v-divider class="divider"></v-divider>
              <div v-if="'LG' in selectedQTL" class="panel-content-title">Genetic Map Coordinates <span class="panel-content"></span></div>
              <div v-if="'LG' in selectedQTL" class="panel-content-title">Linkage group <span class="panel-content">{{ selectedQTL.LG }}</span></div>
              <div v-if="'Start_cM' in selectedQTL" class="panel-content-title">Start pos <span class="panel-content">{{ selectedQTL.Start_cM }}</span></div>
              <div v-if="'Stop_cM' in selectedQTL" class="panel-content-title">End pos <span class="panel-content">{{ selectedQTL.Stop_cM }}</span></div>
              <div v-if="'flipped_bounds' in selectedQTL && selectedQTL.flipped_bounds === true" class="panel-content">Flipped start and stop coordinates <span class="panel-content"></span></div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
    </div>
  </div>
</template>

<script lang="ts">
import GeneralUtils from '@/utils/GeneralUtils'
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class QTLMenu extends Vue {
  panel = [0]

  get selectedQTL () {
    return this.$store.state.graphStore.selectedQTL
  }

  numberWithCommas (n: number) {
    return GeneralUtils.numberWithCommas(n)
  }
}
</script>

<style lang="scss" scoped>
.dot {
    height: 10px !important;
    width: 10px !important;
    transform: translateY(1px);
  }
</style>

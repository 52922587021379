import chroma from 'chroma-js'
import GeneralUtils from '@/utils/GeneralUtils'

export default class ColorUtils {
  private static colorScale = chroma.scale([
    '#ebf0ad', '#f0e1ad', '#bbadf0', '#a1d8e3', '#4c6636',
    '#ff9e00', '#ffcc00', '#66cc66', '#b34700', '#00aacc',
    '#ff6666', '#c2f0c2', '#9966cc', '#ffcc99', '#009999',
    '#ff6666', '#ffcc99', '#ccffcc', '#cc9966', '#66ccff',
    '#663300', '#ff99cc', '#99cc00', '#ff9900', '#3366cc',
    '#cc3333', '#66cc99', '#993366', '#ccff66', '#cc6600',
    '#33cccc', '#cc3300', '#336600', '#ffcc66', '#cc99ff',
    '#ffccff', '#ffcc66', '#336666', '#cc66cc', '#999966'
  ])

  public static getColorScale (size: number, shuffle: boolean): string[] {
    if (shuffle) {
      return GeneralUtils.shuffle(this.colorScale.colors(size))
    } else {
      return this.colorScale.colors(size)
    }
  }

  public static getRandomColor (): string {
    return this.colorScale.colors(20)[Math.random()]
  }
}

<template>
  <v-menu
    v-model="contextMenu.enabled"
    :position-x="contextMenu.positionX"
    :position-y="contextMenu.positionY"
    absolute>
    <v-list dense flat>
      <v-list-item-group>
        <v-list-item>
          <v-list-item-title @click="plotGraphTracks">{{$t('context-menu.plot-graph-paths')}}</v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title @click="plotVCFTracks">{{$t('context-menu.plot-variants')}}</v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title @click="sortByColumn">{{$t('context-menu.sort-by-column')}}</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="contextMenu.vcfTracksVisible">
          <v-list-item-title @click="sortVariants">{{$t('context-menu.sort-variants')}}</v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title @click="zoomIn">{{$t('context-menu.zoom-in')}}</v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title @click="zoomOut">{{$t('context-menu.zoom-out')}}</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="contextMenu.isColumnLink">
          <v-list-item-title @click="followLink(contextMenu.rawCoords)">{{$t('context-menu.follow-link')}}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import Graph from '@/graph/Graph'
import GraphConfig from '@/graph/Config'
import DataProvider from '@/services/DataProvider'
import SortingUtils from '@/utils/SortingUtils'
import * as PIXI from 'pixi.js'
import { ClusterService } from '@/services/ClusterService'
import ColorUtils from '@/utils/ColorUtils'

@Component
export default class ContextMenu extends Vue {
  get contextMenu () {
    return this.$store.state.graphStore.contextMenu
  }

  get lastBinWidth () {
    return this.$store.state.chunkStore.lastBinWidth
  }

  set lastBinWidth (value) {
    this.$store.commit('chunkStore/setLastBinWidth', value)
  }

  get binWidth () {
    return this.$store.state.chunkStore.binWidth
  }

  set binWidth (value) {
    this.$store.commit('chunkStore/setBinWidth', value)
  }

  get denseView () {
    return this.$store.state.metaStore.denseView
  }

  set denseView (value) {
    this.$store.commit('metaStore/setDenseView', value)
  }

  plotGraphTracks () {
    const graphClusters = ClusterService.clusterizeGraphColumn(this.contextMenu.rawCoords.x)
    // set colors for each cluster
    const plotColors: Record<string, string> = {}
    const clusters = Object.keys(graphClusters)
    for (let i = 0; i < clusters.length; i++) {
      plotColors[clusters[i]] = ColorUtils.getRandomColor()
    }
    this.$store.commit('pantoStore/setClusterPlotState', { enabled: true, type: 'graph', clusters: graphClusters, plotColors: plotColors })
  }

  plotVCFTracks () {
    const vcfClusters = ClusterService.clusterizeVCFColumn(this.contextMenu.rawCoords.x)
    // set colors for each cluster
    const plotColors: Record<string, string> = {}
    const clusters = Object.keys(vcfClusters)
    for (let i = 0; i < clusters.length; i++) {
      plotColors[clusters[i]] = ColorUtils.getRandomColor()
    }
    this.$store.commit('pantoStore/setClusterPlotState', { enabled: true, type: 'vcf', clusters: vcfClusters, plotColors: plotColors })
  }

  sortByColumn () {
    if (this.contextMenu.rawCoords) {
      // filling the metaStore.sortingTableGraph Record accordingly
      // console.log('[onColSort] x/col', this.contextMenu.rawCoords.x, Graph.getColForX(this.contextMenu.rawCoords.x))
      Graph.sortByColumnGraph(Graph.getColForX(this.contextMenu.rawCoords.x))

      // Set selected sort option to column
      // IMPORTANT: Changing it will trigger track sorting and redrawing (in Graph.vue)!
      // if 'column' is already selected, then trigger sorting and redrawing manually below
      if (this.$store.state.metaStore.selectedSortOption !== 'column') {
        this.$store.commit('metaStore/setSelectedSortOption', 'column')
      } else {
        // sort by the updated metaStore.sortingTableGraph above, and redraw:
        SortingUtils.sortTracks('graph')
        this.redrawKeepPos()
      }
    }
  }

  sortVariants () {
    if (this.contextMenu.rawCoords) {
      // filling the metaStore.sortingTableVCF Record accordingly
      // console.log('[onVarSort] x/col', this.contextMenu.rawCoords.x, Graph.getColForX(this.contextMenu.rawCoords.x))
      Graph.sortByColumnVCF(Graph.getColForX(this.contextMenu.rawCoords.x))

      if (this.$store.state.metaStore.selectedSortOption !== 'vcf') {
        this.$store.commit('metaStore/setSelectedSortOption', 'vcf')
      } else {
        SortingUtils.sortTracks('vcf')
        this.redrawKeepPos()
      }
    }
  }

  zoomIn () {
    if (this.contextMenu.rawCoords) {
      const binInfo = Graph.getBinInfoForRawX(this.contextMenu.rawCoords.x)
      if (binInfo.type === 'bin') {
        // Set new zoom level to next lower level
        const zoomLevelBefore = this.$store.state.chunkStore.binWidth
        const zoomLevelAfter = DataProvider.getLowerZoomLevel(zoomLevelBefore)
        if (zoomLevelAfter) {
          // Set new zoom level
          this.lastBinWidth = this.$store.state.chunkStore.binWidth
          this.binWidth = zoomLevelAfter

          // Set new max bin
          this.$store.commit('chunkStore/setCurrentMaxBin', DataProvider.getZoomLevelObj(zoomLevelAfter).num_bins)

          // Convert bin nr of old binWidth into bin nr of new binWidth
          const binNumberAfter = Math.round((binInfo.binNumber - 1) * zoomLevelBefore / zoomLevelAfter)

          if (zoomLevelAfter >= GraphConfig.denseViewCutoff) {
            this.denseView = true
          } else {
            this.denseView = false
          }

          if (!this.$store.state.graphStore.loading) {
            // Graph.cleanupOnBinWidthChange()
            this.$store.commit('graphStore/setSelectedHighlight', binNumberAfter)
            this.draw(binNumberAfter, true)
          }
        }
      }
    }
  }

  zoomOut () {
    if (this.contextMenu.rawCoords) {
      const binInfo = Graph.getBinInfoForRawX(this.contextMenu.rawCoords.x)
      if (binInfo.type === 'bin') {
        const zoomLevelBefore = this.$store.state.chunkStore.binWidth
        // Set new zoom level to next lower level
        const zoomLevelAfter = DataProvider.getHigherZoomLevel(zoomLevelBefore)

        if (zoomLevelAfter) {
          // Set new zoom level
          this.lastBinWidth = this.$store.state.chunkStore.binWidth
          this.binWidth = zoomLevelAfter

          // Set new max bin
          this.$store.commit('chunkStore/setCurrentMaxBin', DataProvider.getZoomLevelObj(zoomLevelAfter).num_bins)

          // Convert bin nr of old binWidth into bin nr of new binWidth
          const binNumberAfter = Math.round(binInfo.binNumber * zoomLevelBefore / zoomLevelAfter)

          if (zoomLevelAfter >= GraphConfig.denseViewCutoff) {
            this.denseView = true
          } else {
            this.denseView = false
          }

          if (!this.$store.state.graphStore.loading) {
            // Graph.cleanupOnBinWidthChange()
            this.$store.commit('graphStore/setSelectedHighlight', binNumberAfter)
            this.draw(binNumberAfter, true)
          }
        }
      }
    }
  }

  followLink (coords: PIXI.Point) {
    Graph.followLink(coords.x)
  }

  redrawKeepPos () {
    this.$emit('redrawKeepPos')
  }

  draw (binNumber: number, keepPos: boolean) {
    this.$emit('draw', binNumber, keepPos)
  }
}
</script>
